import React, { useEffect, useState, useContext } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import Select from 'react-select'
import axios from 'axios';
import StudentContext from '../../../../context/StudentContext';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { RombelContext } from '../../../../context/RombelContext';
import apiClient from '../../../../axiosConfig';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
export const EditDataSiswaPage = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };
    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };
    const [errors, setErrors] = useState({});
    const navigate = useNavigate()
    const { student, getStudent } = useContext(StudentContext);
    const { rombels } = useContext(RombelContext)

    const [formData, setFormData] = useState({
        nis: "",
        nisn: "",
        nama: "",
        email: "",
        no_telp: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        no_akta: "",
        jk: "",
        rombel: "",
        rayon: "",
        agama: "",
        tb: "",
        bb: "",
        gol_darah: "",
        asal_smp: "",
        ket_asal_smp: "",
        no_ijazah_smp: "",
        no_ijazah_sd: "",
        skhun: "",
        alamat: "",
        rt: "",
        rw: "",
        kecamatan: "",
        kode_pos: "",
        provinsi: "",
        nama_kota_kabupaten: "",
        nama_dusun: "",
        transportasi: "",
        anak_ke: "",
        ket_tinggal_bersama: "",
        ket_transportasi: "",
        status_data_diri: ""
    });

    const [asalSekolah, setAsalSekolah] = useState([]);

    useEffect(() => {
        const fetchStudentData = async () => {
            if (!student?._id) return;
    
            try {
                const response = await apiClient.get(`${apiEndpoint}/student/${student?._id}`, {
                    headers: {
                        'ngrok-skip-browser-warning': 'true',
                    },
                });
                setFormData(response.data.student);
            } catch (error) {
                console.error('Error fetching student data:', error);
            }
        };
    
        const fetchAsalSekolah = async () => {
            try {
                const response = await apiClient.get(`${apiEndpoint}/data/asal_smp`, {
                    headers: {
                        'ngrok-skip-browser-warning': 'true',
                    },
                });
                const data = Array.isArray(response.data?.data) ? response.data?.data : [];
                setAsalSekolah(data);
            } catch (error) {
                console.error('Error fetching asal SMP data:', error);
            }
        };
    
        fetchStudentData();
        fetchAsalSekolah();
        fetchProvinsi();
    }, [student]);


    const handleChange = (e) => {
        if (e.target == null) {
            const { name, value } = e;
            setFormData((prevData) => ({ ...prevData, [name]: value }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: value ? "" : "Wajib diisi",
            }));
        } else {
            const { name, value } = e.target;
            setFormData((prevData) => ({ ...prevData, [name]: value }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: value ? "" : "Wajib diisi",
            }));
        }
    };

    const handleRombelSelect = (selectedOption) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            rombel: selectedOption.value
        }));
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        const result = await Swal.fire({
            title: "Konfirmasi",
            text: "Apakah Anda yakin data sudah benar?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText: "Tidak",
        });

        if (result.isConfirmed) {
            try {
                await apiClient.put(`${apiEndpoint}/student/update/${student?._id}`, formData);
                let userProfile = JSON.parse(localStorage.getItem("userProfile"))
                userProfile.username = formData.nama
                if (formData.nama != JSON.parse(localStorage.getItem("userProfile")).username) {
                    localStorage.setItem("userProfile", JSON.stringify(userProfile));
                }
                Swal.fire({
                    title: "Sukses!",
                    text: "Berhasil Update Data",
                    icon: "success",
                }).then(() => {
                    getStudent()
                    navigate("/dashboard/data-diri");
                });
            } catch (error) {
                console.log(error, "error ini");
            }
        }
    };


    const [provinsiList, setProvinsiList] = useState([]);
    const [selectedProvinsi, setSelectedProvinsi] = useState(null);
    const [kabupatenList, setKabupatenList] = useState([]);
    const [selectedKabupaten, setSelectedKabupaten] = useState(null);
    const [kecamatanList, setKecamatanList] = useState([]);
    const [selectedKecamatan, setSelectedKecamatan] = useState(null);
    const [kelurahanList, setKelurahanList] = useState([]);
    const [selectedKelurahan, setSelectedKelurahan] = useState(null);

    const isKabupatenDisabled = !selectedProvinsi;
    const isKecamatanDisabled = !selectedKabupaten;
    const isKelurahanDisabled = !selectedKecamatan;

    const fetchProvinsi = async () => {
        try {
            const response = await axios.get('https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json');
            setProvinsiList(response.data);
        } catch (error) {
            console.error('Error fetching provinsi data:', error);
        }
    };


    const fetchKabupaten = async (provinsiId) => {
        try {
            const response = await axios.get(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${provinsiId}.json`);
            setKabupatenList(response.data);
        } catch (error) {
            console.error('Error fetching kabupaten data:', error);
        }
    };

    const fetchKecamatan = async (kabupatenId) => {
        try {
            const response = await axios.get(`https://www.emsifa.com/api-wilayah-indonesia/api/districts/${kabupatenId}.json`);
            setKecamatanList(response.data);
        } catch (error) {
            console.error('Error fetching kecamatan data:', error);
        }
    };

    const fetchKelurahan = async (kecamatanId) => {
        try {
            const response = await axios.get(`https://www.emsifa.com/api-wilayah-indonesia/api/villages/${kecamatanId}.json`);
            setKelurahanList(response.data);
        } catch (error) {
            console.error('Error fetching kelurahan data:', error);
        }
    };
    const handleProvinsiChange = (selectedOption) => {
        setSelectedProvinsi(selectedOption);
        setSelectedKabupaten(null);
        setSelectedKecamatan(null);
        setSelectedKelurahan(null);
        if (selectedOption) {
            fetchKabupaten(selectedOption.value);
            setFormData(prevFormData => ({
                ...prevFormData,
                provinsi: selectedOption.label
            }));
        } else {
            setKabupatenList([]);
            setKecamatanList([]);
            setKelurahanList([]);
            setFormData(prevFormData => ({
                ...prevFormData,
                provinsi: ""
            }));
        }
    };

    const handleKabupatenChange = (selectedOption) => {
        setSelectedKabupaten(selectedOption);
        setSelectedKecamatan(null);
        setSelectedKelurahan(null);
        if (selectedOption) {
            fetchKecamatan(selectedOption.value);
            setFormData(prevFormData => ({
                ...prevFormData,
                nama_kota_kabupaten: selectedOption.label
            }));
        } else {
            setKecamatanList([]);
            setKelurahanList([]);
            setFormData(prevFormData => ({
                ...prevFormData,
                nama_kota_kabupaten: ""
            }));
        }
    };

    const handleKecamatanChange = (selectedOption) => {
        setSelectedKecamatan(selectedOption);
        setSelectedKelurahan(null);
        if (selectedOption) {
            fetchKelurahan(selectedOption.value);
            // Perbarui formData dengan id kecamatan yang dipilih
            setFormData(prevFormData => ({
                ...prevFormData,
                kecamatan: selectedOption.label
            }));
        } else {
            setKelurahanList([]);
            setFormData(prevFormData => ({
                ...prevFormData,
                kecamatan: ""
            }));
        }
    };

    const handleKelurahanChange = (selectedOption) => {
        setSelectedKelurahan(selectedOption);
        if (selectedOption) {
            setFormData(prevFormData => ({
                ...prevFormData,
                nama_kelurahan: selectedOption.label
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                nama_kelurahan: ""
            }));
        }
    };


    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Data Siswa" dua="Edit Data Siswa" urlSatu="/dashboard/data-diri" />
                <div className='card-box shadow-sm p-4 mb-5'>
                    <p style={{ fontSize: '14px', fontStyle: 'italic' }}>
                        <span className='text-danger'>* </span>
                        Data berikut tidak dapat di ubah, hubungi guru pembimbing jika data tidak sesuai
                    </p>
                    <div className='row'>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label className='font-bold'>NIS</label>
                                <input type="text"
                                    disabled
                                    name='nis'
                                    className="form-control"
                                    defaultValue={student.nis}
                                />
                            </div>
                        </div>


                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Email</label>
                                <input type="text"
                                    disabled
                                    name='email'
                                    className="form-control"
                                    defaultValue={student.email}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Rayon</label>
                                <input type="text"
                                    disabled
                                    name='rayon'
                                    className={`form-control`}
                                    defaultValue={student.rayon}
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className='d-flex mb-4 w-100'>
                    <div style={{ border: `5px solid  ${currentStep === 1 ? '#1c367e' : '#e9e9e9'} `, borderRadius: "10px" }} className="w-100" />
                    <div style={{ border: `5px solid  ${currentStep === 2 ? '#1c367e' : '#e9e9e9'} `, borderRadius: "10px" }} className="w-100 ml-2" />
                    <div style={{ border: `5px solid  ${currentStep === 3 ? '#1c367e' : '#e9e9e9'} `, borderRadius: "10px" }} className="w-100 ml-2" />
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                    {currentStep === 1 && (
                        <div className='card-box shadow-sm p-4'>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Nama Lengkap</label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.nama ? 'is-invalid' : ''}`}
                                            defaultValue={formData.nama}
                                            onChange={(e) => handleChange(e)}
                                            name='nama'
                                        />
                                        {errors.nama && <p style={{ color: "red" }}>{errors.nama}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Rombel</label>
                                        <Select
                                            options={rombels.map(rombel => ({ label: rombel.nama_rombel, value: rombel.nama_rombel }))}
                                            onChange={handleRombelSelect}
                                            defaultValue={formData.rombel}
                                            placeholder={formData.rombel ? formData.rombel : "Pilih Rombel"}
                                            name='rombel'
                                            id='rombel'
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>NISN <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.nisn ? 'is-invalid' : ''}`}
                                            defaultValue={formData.nisn}
                                            onChange={(e) => handleChange(e)}
                                            name='nisn'
                                        />
                                        {errors.nisn && <p style={{ color: "red" }}>{errors.nisn}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>No HP  <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.no_telp ? 'is-invalid' : ''}`}
                                            defaultValue={formData.no_telp}
                                            onChange={(e) => handleChange(e)}
                                            name='no_telp'
                                        />
                                        {errors.no_telp && <p style={{ color: "red" }}>{errors.no_telp}</p>}

                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Tempat Lahir  <span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.tempat_lahir ? 'is-invalid' : ''}`}
                                            defaultValue={formData.tempat_lahir}
                                            onChange={(e) => handleChange(e)}
                                            name='tempat_lahir'
                                        />
                                        {errors.tempat_lahir && <p style={{ color: "red" }}>{errors.tempat_lahir}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Tanggal Lahir  <span className='text-danger'>*</span></label>
                                        <input
                                            type='date'
                                            className={`form-control ${errors.tanggal_lahir ? 'is-invalid' : ''}`}
                                            defaultValue={formData.tanggal_lahir}
                                            onChange={(e) => handleChange(e)}
                                            name='tanggal_lahir'
                                        />
                                        {errors.tanggal_lahir && <p style={{ color: "red" }}>{errors.tanggal_lahir}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Jenis Kelamin  <span className='text-danger'>*</span></label>
                                        <select
                                            name='jk'
                                            className={`form-select ${errors.jk ? 'is-invalid' : ''}`}
                                            value={formData.jk}
                                            onChange={(e) => handleChange(e)}>
                                            {/* <option value="" disabled>{formData.jk ? formData.jk : "Pilih Jenis Kelamin"}</option> */}
                                            <option value="">Pilih Jenis Kelamin</option>
                                            <option value="P">P</option>
                                            <option value="L">L</option>
                                        </select>
                                        {errors.jk && <p style={{ color: "red" }}>{errors.jk}</p>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Agama  <span className='text-danger'>*</span></label>
                                        <select
                                            name='agama'
                                            className={`form-select ${errors.agama ? 'is-invalid' : ''}`}
                                            value={formData.agama}
                                            onChange={(e) => handleChange(e)}>

                                            {/* <option value="" disabled>{formData.agama ? formData.agama : "Pilih Agama"}</option> */}
                                            <option value="">Pilih Agama</option>
                                            <option value="Islam">Islam</option>
                                            <option value="Protestan">Protestan</option>
                                            <option value="Katholik">Katholik</option>
                                            <option value="Hindu">Hindu</option>
                                            <option value="Budha">Budha</option>
                                            <option value="Kong Hu Chu">Kong Hu Chu</option>
                                        </select>
                                        {errors.agama && <p style={{ color: "red" }}>{errors.agama}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Tinggi Badan (TB)  <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.tb ? 'is-invalid' : ''}`}
                                            defaultValue={formData.tb}
                                            onChange={(e) => handleChange(e)}
                                            name='tb'
                                        />
                                        {errors.tb && <p style={{ color: "red" }}>{errors.tb}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Berat Badan (BB)  <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.bb ? 'is-invalid' : ''}`}
                                            defaultValue={formData.bb}
                                            onChange={(e) => handleChange(e)}
                                            name='bb'
                                        />
                                        {errors.bb && <p style={{ color: "red" }}>{errors.bb}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Golongan Darah  <span className='text-danger'>*</span></label>
                                        {/* <input
                                            type='text'
                                            className={`form-control ${errors.gol_darah ? 'is-invalid' : ''}`}
                                            defaultValue={formData.gol_darah}
                                            onChange={(e) => handleChange(e)}
                                            name='gol_darah'
                                        /> */}
                                        <select
                                            className={`form-select ${errors.gol_darah ? 'is-invalid' : ''}`}
                                            value={formData.gol_darah}
                                            name='gol_darah'
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Golongan Darah</option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="O">O</option>
                                            <option value="AB">AB</option>
                                        </select>
                                        {errors.gol_darah && <p style={{ color: "red" }}>{errors.gol_darah}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Tinggal Bersama  <span className='text-danger'>*</span></label>
                                        <select
                                            className={`form-select ${errors.tinggal_bersama ? 'is-invalid' : ''}`}
                                            value={formData.tinggal_bersama}
                                            name='tinggal_bersama'
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Tinggal Bersama</option>
                                            <option value="Bersama Orang Tua">Bersama Orang Tua</option>
                                            <option value="Wali">Wali</option>
                                            <option value="Kost">Kost</option>
                                            <option value="Asrama">Asrama</option>
                                            <option value="Panti Asuhan">Panti Asuhan</option>
                                            <option value="Pesantren">Pesantren</option>
                                            <option value="Lainnya">Lainnya</option>
                                        </select>
                                        {errors.tinggal_bersama && <p style={{ color: "red" }}>{errors.tinggal_bersama}</p>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Anak Ke- <span className='text-danger'>*</span></label>
                                        <input
                                            type='number' min={1}
                                            className={`form-control ${errors.anak_ke ? 'is-invalid' : ''}`}
                                            defaultValue={formData.anak_ke}
                                            onChange={(e) => handleChange(e)}
                                            name='anak_ke'
                                        />
                                        {errors.anak_ke && <p style={{ color: "red" }}>{errors.anak_ke}</p>}
                                    </div>
                                </div>
                                {formData.tinggal_bersama == 'Lainnya' ? (
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Keterangan Tinggal Bersama <span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    className={`form-control ${errors.tinggal_bersama ? 'is-invalid' : ''}`}
                                                    defaultValue={formData.ket_tinggal_bersama}
                                                    onChange={(e) => handleChange(e)}
                                                    name='ket_tinggal_bersama'
                                                />
                                        </div>
                                    </div>
                                ) : ''}
                            </div>
                            <button type="button" className="btn btn-primary bg-blue" onClick={nextStep}>Selanjutnya</button>
                        </div>
                    )}

                    {currentStep === 2 && (

                        <div className='card-box shadow-sm p-4'>
                            <div className="row">

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Asal SMP  <span className='text-danger'>*</span> <small>(Jika data SMP tidak ditemukan, silakan pilih LAINNYA lalu isi manual pada tempat yang sudah disediakan)</small></label>
                                        {/* <input
                                            type='text'
                                            className={`form-control ${errors.asal_smp ? 'is-invalid' : ''}`}
                                            defaultValue={formData.asal_smp}
                                            onChange={(e) => handleChange(e)}
                                            name='asal_smp'
                                        /> */}
                                        <Select
                                            options={asalSekolah.map(smp => ({ label: smp.nama_sekolah, name: 'asal_smp', value: smp.nama_sekolah }))}
                                            onChange={(e) => handleChange(e)}
                                            defaultValue={formData.asal_smp}
                                            name='asal_smp'
                                            placeholder={formData.asal_smp ? formData.asal_smp : "Pilih Asal SMP"}
                                        />
                                        {errors.asal_smp && <p style={{ color: "red" }}>{errors.asal_smp}</p>}
                                    </div>
                                </div>

                                {formData.asal_smp == 'Lainnya' ? (
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Asal SMP (Lainnya) <span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    className={`form-control ${errors.asal_smp ? 'is-invalid' : ''}`}
                                                    defaultValue={formData.ket_asal_smp}
                                                    onChange={(e) => handleChange(e)}
                                                    name='ket_asal_smp'
                                                />
                                        </div>
                                    </div>
                                </div>
                                ) : ''}

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Moda Transportasi <span className='text-danger'>*</span></label>
                                        <select
                                            className={`form-select ${errors.transportasi ? 'is-invalid' : ''}`}
                                            defaultValue={formData.transportasi}
                                            name='transportasi'
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Moda Transportasi</option>
                                            <option value="Jalan Kaki">Jalan Kaki</option>
                                            <option value="Kendaraan Pribadi">Kendaraan pribadi</option>
                                            <option value="Kendaraan Umum/Angkot/Bis Kota">Kendaraan Umum/Angkot/Bis Kota</option>
                                            <option value="Jemputan Sekolah">Jemputan Sekolah</option>
                                            <option value="Kereta Api">Kereta Api</option>
                                            <option value="Ojek">Ojek</option>
                                            <option value="Andong/Bendi/Sado/ Dokar/Delman/Beca">Andong/Bendi/Sado/ Dokar/Delman/Beca</option>
                                            <option value="Perahu penyebrangan/Rakit/Getek">Perahu penyebrangan/Rakit/Getek</option>
                                            <option value="Lainnya">Lainnya</option>
                                        </select>
                                    </div>
                                </div>
                                
                                {formData.transportasi == 'Lainnya' ? (
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Keterangan Moda Transportasi <span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    className={`form-control ${errors.transportasi ? 'is-invalid' : ''}`}
                                                    defaultValue={formData.ket_transportasi}
                                                    onChange={(e) => handleChange(e)}
                                                    name='ket_transportasi'
                                                />
                                        </div>
                                    </div>
                                </div>
                                ) : ''}
                                
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>No. Seri Ijazah SD  <span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.no_ijazah_sd ? 'is-invalid' : ''}`}
                                            defaultValue={formData.no_ijazah_sd}
                                            onChange={(e) => handleChange(e)}
                                            name='no_ijazah_sd'
                                        />
                                        {errors.no_ijazah_sd && <p style={{ color: "red" }}>{errors.no_ijazah_sd}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>No. Seri Ijazah SMP  <span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.no_ijazah_smp ? 'is-invalid' : ''}`}
                                            defaultValue={formData.no_ijazah_smp}
                                            onChange={(e) => handleChange(e)}
                                            name='no_ijazah_smp'
                                        />
                                        {errors.no_ijazah_smp && <p style={{ color: "red" }}>{errors.no_ijazah_smp}</p>}

                                    </div>
                                </div>


                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>No. Akta Kelahiran<span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.no_akta ? 'is-invalid' : ''}`}
                                            defaultValue={formData.no_akta}
                                            onChange={(e) => handleChange(e)}
                                            name='no_akta'
                                        />
                                        {errors.no_akta && <p style={{ color: "red" }}>{errors.no_akta}</p>}

                                    </div>
                                </div>

                            </div>
                            <button type="button" className='btn  btn-danger' onClick={prevStep}>
                                Sebelumnya
                            </button>
                            <button type="button" className="btn btn-primary ml-2 bg-blue" onClick={nextStep}>Selanjutnya</button>

                        </div>
                    )}

                    {currentStep === 3 && (
                        <div className='card-box shadow-sm p-4'>
                            <div className="row">

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Alamat <span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.alamat ? 'is-invalid' : ''}`}
                                            defaultValue={formData.alamat}
                                            onChange={(e) => handleChange(e)}
                                            name='alamat'
                                        />
                                        {errors.alamat && <p style={{ color: "red" }}>{errors.alamat}</p>}

                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Provinsi <span className='text-danger'>*</span></label>
                                        <Select
                                            options={provinsiList.map(provinsi => ({ label: provinsi.name, value: provinsi.id }))}
                                            onChange={handleProvinsiChange}
                                            defaultValue={formData.provinsi}
                                            name='provinsi'
                                            placeholder={formData.provinsi ? formData.provinsi : "Pilih Provinsi"}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Kabupaten/Kota <span className='text-danger'>*</span></label>
                                        <Select
                                            options={kabupatenList.map(kabupaten => ({ label: kabupaten.name, value: kabupaten.id }))}
                                            onChange={handleKabupatenChange}
                                            defaultValue={formData.nama_kota_kabupaten}
                                            placeholder={formData.nama_kota_kabupaten ? formData.nama_kota_kabupaten : "Pilih Kabupaten"}
                                            name='nama_kota_kabupaten'
                                            isDisabled={isKabupatenDisabled}

                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Kecamatan <span className='text-danger'>*</span></label>
                                        <Select
                                            options={kecamatanList.map(kecamatan => ({ label: kecamatan.name, value: kecamatan.id }))}
                                            onChange={handleKecamatanChange}
                                            defaultValue={formData.kecamatan}
                                            placeholder={formData.kecamatan ? formData.kecamatan : "Pilih Kecamatan"}
                                            name='kecamatan'
                                            isDisabled={isKecamatanDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Kelurahan <span className='text-danger'>*</span></label>
                                        <Select
                                            options={kelurahanList.map(kelurahan => ({ label: kelurahan.name, value: kelurahan.id }))}
                                            onChange={handleKelurahanChange}
                                            defaultValue={formData.nama_kelurahan}
                                            placeholder={formData.nama_kelurahan ? formData.nama_kelurahan : "Pilih Provinsi"}
                                            name='nama_keluharan'
                                            isDisabled={isKelurahanDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>RT <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.rt ? 'is-invalid' : ''}`}
                                            defaultValue={formData.rt}
                                            onChange={(e) => handleChange(e)}
                                            name='rt'
                                        />
                                        {errors.rt && <p style={{ color: "red" }}>{errors.rt}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>RW <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.rw ? 'is-invalid' : ''}`}
                                            defaultValue={formData.rw}
                                            onChange={(e) => handleChange(e)}
                                            name='rw'
                                        />
                                        {errors.rw && <p style={{ color: "red" }}>{errors.rw}</p>}

                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Kode Pos <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.kode_pos ? 'is-invalid' : ''}`}
                                            defaultValue={formData.kode_pos}
                                            onChange={(e) => handleChange(e)}
                                            name='kode_pos'
                                        />
                                        {errors.kode_pos && <p style={{ color: "red" }}>{errors.kode_pos}</p>}

                                    </div>
                                </div>
                            </div>
                            <button type="button" className='btn  btn-danger' onClick={prevStep}>
                                Sebelumnya
                            </button>
                            <button type="button" className="btn btn-primary ml-2 bg-blue" onClick={nextStep}>Selanjutnya</button>
                        </div>
                    )}

                    {currentStep === 4 && (
                        <div className='card-box shadow-sm p-4'>
                            <div className="row">

                                <div className="col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label>Persetujuan dan Kirim <span className='text-danger'>*</span></label>
                                        <div className="form-inline">
                                            <input type="checkbox" name="status_data_diri" id="status_data_diri" className={'form-check mr-1'}
                                                onChange={(e) => handleChange(e)} value={'Konfirmasi'} required={'required'}
                                            /> Dengan ini saya menyetujui data yang saya isi sesuai dengan data Ijazah SMP, Ijazah SD, Akta Kelahiran dan KK
                                        </div>
                                        {errors.status_data_diri && <p style={{ color: "red" }}>{errors.status_data_diri}</p>}

                                    </div>
                                </div>

                            </div>
                            <button type="button" className='btn  btn-danger' onClick={prevStep}>
                                Sebelumnya
                            </button>
                            <button type="submit" className='btn bg-blue text-white ml-3'>Kirim</button>
                        </div>
                    )}

                </form>
            </LayoutDashboard>
        </div >

    )
}
