import axios from 'axios';
import React, { useContext } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GelBreadcrumb from '../../../components/globalcomp/GelBreadcrumb';
import LayoutDashboard from '../../../layouts/LayoutDashboard';
import AuthContext from '../../../context/AuthContext';
import { RombelContext } from '../../../context/RombelContext';
import StudentContext from '../../../context/StudentContext';
import Swal from 'sweetalert2';
import Select from 'react-select'
import apiClient from '../../../axiosConfig';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const EditStudentPage = () => {
    const { id } = useParams();
    const { user } = useContext(AuthContext)

    const [student, setStudent] = useState({});
    const [asalSekolah, setAsalSekolah] = useState([]);
    
    const [errors, setErrors] = useState({});
    const navigate = useNavigate()
    const { rombels } = useContext(RombelContext)

    // Error disini
    const [formData, setFormData] = useState({
        nis: "",
        nisn: "",
        nama: "",
        email: "",
        no_telp: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        no_akta: "",
        jk: "",
        rombel: "",
        rayon: "",
        agama: "",
        tb: "",
        bb: "",
        gol_darah: "",
        asal_smp: "",
        ket_asal_smp: "",
        no_ijazah_smp: "",
        no_ijazah_sd: "",
        skhun: "",
        alamat: "",
        rt: "",
        rw: "",
        kecamatan: "",
        kode_pos: "",
        provinsi: "",
        nama_kota_kabupaten: "",
        nama_dusun: "",
        transportasi: "",
        anak_ke: "",
        tinggal_bersama: "",
        ket_tinggal_bersama: "",
        ket_transportasi: "",
        status_data_diri: "",
        no_kk: "",
        nik: "",
        nama_ayah: "",
        nik_ayah: "",
        tempat_lahir_ayah: "",
        tanggal_lahir_ayah: "",
        pendidikan_ayah: "",
        pekerjaan_ayah: "",
        penghasilan_ayah: "",
        not_telp_ayah: "",
        nama_ibu: "",
        nik_ibu: "",
        tempat_lahir_ibu: "",
        tanggal_lahir_ibu: "",
        pendidikan_ibu: "",
        pekerjaan_ibu: "",
        penghasilan_ibu: "",
        not_telp_ibu: "",
        nama_wali: "",
        nik_wali: "",
        tanggal_lahir_wali: "",
        pendidikan_wali: "",
        pekerjaan_wali: "",
        penghasilan_wali: "",
        not_telp_ibu: "",
        ada_wali: "",
    });

    const [currentStep, setCurrentStep] = useState(1);
    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };
    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };
    useEffect(() => {
        apiClient.get(process.env.REACT_APP_API_ENDPOINT + `/student/${id}`, {
            headers: {
                'ngrok-skip-browser-warning': 'true',
            },
        }).then(res => {
            setFormData(res.data.student);
            // const keluargaIdData = res?.student.keluarga_id || null;
            // console.log(res.student)
            // setFormData((prevData) => ({ ...prevData, keluargaIdData }))
            // console.log(formData)
        }).catch(err => {
            console.log('Error fetching data:', err)
        });
        // setStudent()
        // setFormData()
        // console.log(formData)

        apiClient.get(process.env.REACT_APP_API_ENDPOINT + `/data/asal_smp`, {
            headers: {
                'ngrok-skip-browser-warning': 'true',
            },
        }).then(res => {
            setAsalSekolah(res.data.data);
        }).catch(err => {
            console.log('Error fetching data:', err)
        });
        setAsalSekolah()
        fetchProvinsi()
    }, [id]);


    const handleChange = (e) => {
        if (e.target == null) {
            const { name, value } = e;
            setFormData((prevData) => ({ ...prevData, [name]: value }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: value ? "" : "Wajib diisi",
            }));
        } else {
            const { name, value } = e.target;
            setFormData((prevData) => ({ ...prevData, [name]: value }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: value ? "" : "Wajib diisi",
            }));
        }
    };

    const handleCheck = () => {
        setFormData((prevData) => ({ ...prevData, 'status_data_diri': 'Konfirmasi' }));
        setFormData((prevData) => ({ ...prevData, 'status_data_family': 'Konfirmasi' }));
        setFormData((prevData) => ({ ...prevData, 'status_data_dokumen': 'Konfirmasi' }));
    }

    const handleRombelSelect = (selectedOption) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            rombel: selectedOption.value
        }));
    };

    const handleChangeNumber = (e,exactLength) => {
        const { name, value } = e.target;
        // onKeyDown={(e) => (e.target.value.length==16) ? true : false}
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        
        if (value.length > exactLength) {
            let newValue = value.slice(0, exactLength);
            setFormData((prevData) => ({ ...prevData, [name]: newValue }));
        }
        
        if (value.length !== exactLength) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: `${name} Harus tepat ${exactLength} digit`,
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "",
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const result = await Swal.fire({
            title: "Konfirmasi",
            text: "Apakah Anda yakin data sudah benar?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText: "Tidak",
        });

        if (result.isConfirmed) {
            try {
                // console.log(formData)
                await apiClient.put(`${apiEndpoint}/student/update/${id}`, formData);
                Swal.fire({
                    title: "Sukses!",
                    text: "Berhasil Update Data",
                    icon: "success",
                }).then(() => {
                    navigate(`/dashboard/student/detail/${id}`);
                });
            } catch (error) {
                console.log(error, "error ini");
            }
        }
    };

    const [provinsiList, setProvinsiList] = useState([]);
    const [selectedProvinsi, setSelectedProvinsi] = useState(null);
    const [kabupatenList, setKabupatenList] = useState([]);
    const [selectedKabupaten, setSelectedKabupaten] = useState(null);
    const [kecamatanList, setKecamatanList] = useState([]);
    const [selectedKecamatan, setSelectedKecamatan] = useState(null);
    const [kelurahanList, setKelurahanList] = useState([]);
    const [selectedKelurahan, setSelectedKelurahan] = useState(null);

    const isKabupatenDisabled = !selectedProvinsi;
    const isKecamatanDisabled = !selectedKabupaten;
    const isKelurahanDisabled = !selectedKecamatan;

    const fetchProvinsi = async () => {
        try {
            const response = await axios.get('https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json');
            setProvinsiList(response.data);
        } catch (error) {
            console.error('Error fetching provinsi data:', error);
        }
    };


    const fetchKabupaten = async (provinsiId) => {
        try {
            const response = await axios.get(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${provinsiId}.json`);
            setKabupatenList(response.data);
        } catch (error) {
            console.error('Error fetching kabupaten data:', error);
        }
    };

    const fetchKecamatan = async (kabupatenId) => {
        try {
            const response = await axios.get(`https://www.emsifa.com/api-wilayah-indonesia/api/districts/${kabupatenId}.json`);
            setKecamatanList(response.data);
        } catch (error) {
            console.error('Error fetching kecamatan data:', error);
        }
    };

    const fetchKelurahan = async (kecamatanId) => {
        try {
            const response = await axios.get(`https://www.emsifa.com/api-wilayah-indonesia/api/villages/${kecamatanId}.json`);
            setKelurahanList(response.data);
        } catch (error) {
            console.error('Error fetching kelurahan data:', error);
        }
    };
    const handleProvinsiChange = (selectedOption) => {
        setSelectedProvinsi(selectedOption);
        setSelectedKabupaten(null);
        setSelectedKecamatan(null);
        setSelectedKelurahan(null);
        if (selectedOption) {
            fetchKabupaten(selectedOption.value);
            setFormData(prevFormData => ({
                ...prevFormData,
                provinsi: selectedOption.label
            }));
        } else {
            setKabupatenList([]);
            setKecamatanList([]);
            setKelurahanList([]);
            setFormData(prevFormData => ({
                ...prevFormData,
                provinsi: ""
            }));
        }
    };

    const handleKabupatenChange = (selectedOption) => {
        setSelectedKabupaten(selectedOption);
        setSelectedKecamatan(null);
        setSelectedKelurahan(null);
        if (selectedOption) {
            fetchKecamatan(selectedOption.value);
            setFormData(prevFormData => ({
                ...prevFormData,
                nama_kota_kabupaten: selectedOption.label
            }));
        } else {
            setKecamatanList([]);
            setKelurahanList([]);
            setFormData(prevFormData => ({
                ...prevFormData,
                nama_kota_kabupaten: ""
            }));
        }
    };

    const handleKecamatanChange = (selectedOption) => {
        setSelectedKecamatan(selectedOption);
        setSelectedKelurahan(null);
        if (selectedOption) {
            fetchKelurahan(selectedOption.value);
            // Perbarui formData dengan id kecamatan yang dipilih
            setFormData(prevFormData => ({
                ...prevFormData,
                kecamatan: selectedOption.label
            }));
        } else {
            setKelurahanList([]);
            setFormData(prevFormData => ({
                ...prevFormData,
                kecamatan: ""
            }));
        }
    };

    const handleKelurahanChange = (selectedOption) => {
        setSelectedKelurahan(selectedOption);
        if (selectedOption) {
            setFormData(prevFormData => ({
                ...prevFormData,
                nama_kelurahan: selectedOption.label
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                nama_kelurahan: ""
            }));
        }
    };

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Data Siswa" dua="Edit Data Siswa" urlSatu={`/dashboard/student/detail/${id}`} />
                <div className='card-box shadow-sm p-4 mb-5'>
                    <p style={{ fontSize: '14px', fontStyle: 'italic' }}>
                        <span className='text-danger'>* </span>
                        Data berikut tidak dapat di ubah, hubungi guru pembimbing jika data tidak sesuai
                    </p>
                    <div className='row'>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label className='font-bold'>NIS</label>
                                <input type="text"
                                    disabled
                                    name='nis'
                                    className="form-control"
                                    defaultValue={formData.nis}
                                />
                            </div>
                        </div>


                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Email</label>
                                <input type="text"
                                    disabled
                                    name='email'
                                    className="form-control"
                                    defaultValue={formData.email}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Rayon</label>
                                <input type="text"
                                    disabled
                                    name='rayon'
                                    className={`form-control`}
                                    defaultValue={formData.rayon}
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className='d-flex mb-4 w-100'>
                    <div style={{ border: `5px solid  ${currentStep === 1 ? '#1c367e' : '#e9e9e9'} `, borderRadius: "10px" }} className="w-100" />
                    <div style={{ border: `5px solid  ${currentStep === 2 ? '#1c367e' : '#e9e9e9'} `, borderRadius: "10px" }} className="w-100 ml-2" />
                    <div style={{ border: `5px solid  ${currentStep === 3 ? '#1c367e' : '#e9e9e9'} `, borderRadius: "10px" }} className="w-100 ml-2" />
                    <div style={{ border: `5px solid  ${currentStep === 4 ? '#1c367e' : '#e9e9e9'} `, borderRadius: "10px" }} className="w-100 ml-2" />
                    <div style={{ border: `5px solid  ${currentStep === 5 ? '#1c367e' : '#e9e9e9'} `, borderRadius: "10px" }} className="w-100 ml-2" />
                    <div style={{ border: `5px solid  ${currentStep === 6 ? '#1c367e' : '#e9e9e9'} `, borderRadius: "10px" }} className="w-100 ml-2" />
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                    {currentStep === 1 && (
                        <div className='card-box shadow-sm p-4'>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Nama Lengkap</label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.nama ? 'is-invalid' : ''}`}
                                            defaultValue={formData.nama}
                                            onChange={(e) => handleChange(e)}
                                            name='nama'
                                        />
                                        {errors.nama && <p style={{ color: "red" }}>{errors.nama}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Rombel</label>
                                        <Select
                                            options={rombels.map(rombel => ({ label: rombel.nama_rombel, value: rombel.nama_rombel }))}
                                            onChange={handleRombelSelect}
                                            defaultValue={formData.rombel}
                                            placeholder={formData.rombel ? formData.rombel : "Pilih Rombel"}
                                            name='rombel'
                                            id='rombel'
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>NISN <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.nisn ? 'is-invalid' : ''}`}
                                            defaultValue={formData.nisn}
                                            onChange={(e) => handleChange(e)}
                                            name='nisn'
                                        />
                                        {errors.nisn && <p style={{ color: "red" }}>{errors.nisn}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>No HP  <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.no_telp ? 'is-invalid' : ''}`}
                                            defaultValue={formData.no_telp}
                                            onChange={(e) => handleChange(e)}
                                            name='no_telp'
                                        />
                                        {errors.no_telp && <p style={{ color: "red" }}>{errors.no_telp}</p>}

                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Tempat Lahir  <span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.tempat_lahir ? 'is-invalid' : ''}`}
                                            defaultValue={formData.tempat_lahir}
                                            onChange={(e) => handleChange(e)}
                                            name='tempat_lahir'
                                        />
                                        {errors.tempat_lahir && <p style={{ color: "red" }}>{errors.tempat_lahir}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Tanggal Lahir  <span className='text-danger'>*</span></label>
                                        <input
                                            type='date'
                                            className={`form-control ${errors.tanggal_lahir ? 'is-invalid' : ''}`}
                                            defaultValue={formData.tanggal_lahir}
                                            onChange={(e) => handleChange(e)}
                                            name='tanggal_lahir'
                                        />
                                        {errors.tanggal_lahir && <p style={{ color: "red" }}>{errors.tanggal_lahir}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Jenis Kelamin  <span className='text-danger'>*</span></label>
                                        <select
                                            name='jk'
                                            className={`form-select ${errors.jk ? 'is-invalid' : ''}`}
                                            value={formData.jk}
                                            onChange={(e) => handleChange(e)}>
                                            {/* <option value="" disabled>{formData.jk ? formData.jk : "Pilih Jenis Kelamin"}</option> */}
                                            <option value="">Pilih Jenis Kelamin</option>
                                            <option value="P">P</option>
                                            <option value="L">L</option>
                                        </select>
                                        {errors.jk && <p style={{ color: "red" }}>{errors.jk}</p>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Agama  <span className='text-danger'>*</span></label>
                                        <select
                                            name='agama'
                                            className={`form-select ${errors.agama ? 'is-invalid' : ''}`}
                                            value={formData.agama}
                                            onChange={(e) => handleChange(e)}>

                                            {/* <option value="" disabled>{formData.agama ? formData.agama : "Pilih Agama"}</option> */}
                                            <option value="">Pilih Agama</option>
                                            <option value="Islam">Islam</option>
                                            <option value="Protestan">Protestan</option>
                                            <option value="Katholik">Katholik</option>
                                            <option value="Hindu">Hindu</option>
                                            <option value="Budha">Budha</option>
                                            <option value="Kong Hu Chu">Kong Hu Chu</option>
                                        </select>
                                        {errors.agama && <p style={{ color: "red" }}>{errors.agama}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Tinggi Badan (TB)  <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.tb ? 'is-invalid' : ''}`}
                                            defaultValue={formData.tb}
                                            onChange={(e) => handleChange(e)}
                                            name='tb'
                                        />
                                        {errors.tb && <p style={{ color: "red" }}>{errors.tb}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Berat Badan (BB)  <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.bb ? 'is-invalid' : ''}`}
                                            defaultValue={formData.bb}
                                            onChange={(e) => handleChange(e)}
                                            name='bb'
                                        />
                                        {errors.bb && <p style={{ color: "red" }}>{errors.bb}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Golongan Darah  <span className='text-danger'>*</span></label>
                                        {/* <input
                                            type='text'
                                            className={`form-control ${errors.gol_darah ? 'is-invalid' : ''}`}
                                            defaultValue={formData.gol_darah}
                                            onChange={(e) => handleChange(e)}
                                            name='gol_darah'
                                        /> */}
                                        <select
                                            className={`form-select ${errors.gol_darah ? 'is-invalid' : ''}`}
                                            value={formData.gol_darah}
                                            name='gol_darah'
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Golongan Darah</option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="O">O</option>
                                            <option value="AB">AB</option>
                                        </select>
                                        {errors.gol_darah && <p style={{ color: "red" }}>{errors.gol_darah}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Tinggal Bersama  <span className='text-danger'>*</span></label>
                                        <select
                                            className={`form-select ${errors.tinggal_bersama ? 'is-invalid' : ''}`}
                                            value={formData.tinggal_bersama}
                                            name='tinggal_bersama'
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Tinggal Bersama</option>
                                            <option value="Bersama Orang Tua">Bersama Orang Tua</option>
                                            <option value="Wali">Wali</option>
                                            <option value="Kost">Kost</option>
                                            <option value="Asrama">Asrama</option>
                                            <option value="Panti Asuhan">Panti Asuhan</option>
                                            <option value="Pesantren">Pesantren</option>
                                            <option value="Lainnya">Lainnya</option>
                                        </select>
                                        {errors.tinggal_bersama && <p style={{ color: "red" }}>{errors.tinggal_bersama}</p>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Anak Ke- <span className='text-danger'>*</span></label>
                                        <input
                                            type='number' min={1}
                                            className={`form-control ${errors.anak_ke ? 'is-invalid' : ''}`}
                                            defaultValue={formData.anak_ke}
                                            onChange={(e) => handleChange(e)}
                                            name='anak_ke'
                                        />
                                        {errors.anak_ke && <p style={{ color: "red" }}>{errors.anak_ke}</p>}
                                    </div>
                                </div>
                                {formData.tinggal_bersama == 'Lainnya' ? (
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Keterangan Tinggal Bersama <span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    className={`form-control ${errors.tinggal_bersama ? 'is-invalid' : ''}`}
                                                    defaultValue={formData.ket_tinggal_bersama}
                                                    onChange={(e) => handleChange(e)}
                                                    name='ket_tinggal_bersama'
                                                />
                                        </div>
                                    </div>
                                ) : ''}
                            </div>
                            <button type="button" className="btn btn-primary bg-blue" onClick={nextStep}>Selanjutnya</button>
                        </div>
                    )}

                    {currentStep === 2 && (

                        <div className='card-box shadow-sm p-4'>
                            <div className="row">

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Asal SMP  <span className='text-danger'>*</span> <small>(Jika data SMP tidak ditemukan, silakan pilih LAINNYA lalu isi manual pada tempat yang sudah disediakan)</small></label>
                                        {/* <input
                                            type='text'
                                            className={`form-control ${errors.asal_smp ? 'is-invalid' : ''}`}
                                            defaultValue={formData.asal_smp}
                                            onChange={(e) => handleChange(e)}
                                            name='asal_smp'
                                        /> */}
                                        <Select
                                            options={asalSekolah.map(smp => ({ label: smp.nama_sekolah, name: 'asal_smp', value: smp.nama_sekolah }))}
                                            onChange={(e) => handleChange(e)}
                                            defaultValue={formData.asal_smp}
                                            name='asal_smp'
                                            placeholder={formData.asal_smp ? formData.asal_smp : "Pilih Asal SMP"}
                                        />
                                        {errors.asal_smp && <p style={{ color: "red" }}>{errors.asal_smp}</p>}
                                    </div>
                                </div>

                                {formData.asal_smp == 'Lainnya' ? (
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Asal SMP (Lainnya) <span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    className={`form-control ${errors.asal_smp ? 'is-invalid' : ''}`}
                                                    defaultValue={formData.ket_asal_smp}
                                                    onChange={(e) => handleChange(e)}
                                                    name='ket_asal_smp'
                                                />
                                        </div>
                                    </div>
                                </div>
                                ) : ''}

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Moda Transportasi <span className='text-danger'>*</span></label>
                                        <select
                                            className={`form-select ${errors.transportasi ? 'is-invalid' : ''}`}
                                            defaultValue={formData.transportasi}
                                            name='transportasi'
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Moda Transportasi</option>
                                            <option value="Jalan Kaki">Jalan Kaki</option>
                                            <option value="Kendaraan Pribadi">Kendaraan pribadi</option>
                                            <option value="Kendaraan Umum/Angkot/Bis Kota">Kendaraan Umum/Angkot/Bis Kota</option>
                                            <option value="Jemputan Sekolah">Jemputan Sekolah</option>
                                            <option value="Kereta Api">Kereta Api</option>
                                            <option value="Ojek">Ojek</option>
                                            <option value="Andong/Bendi/Sado/ Dokar/Delman/Beca">Andong/Bendi/Sado/ Dokar/Delman/Beca</option>
                                            <option value="Perahu penyebrangan/Rakit/Getek">Perahu penyebrangan/Rakit/Getek</option>
                                            <option value="Lainnya">Lainnya</option>
                                        </select>
                                    </div>
                                </div>
                                
                                {formData.transportasi == 'Lainnya' ? (
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Keterangan Moda Transportasi <span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    className={`form-control ${errors.transportasi ? 'is-invalid' : ''}`}
                                                    defaultValue={formData.ket_transportasi}
                                                    onChange={(e) => handleChange(e)}
                                                    name='ket_transportasi'
                                                />
                                        </div>
                                    </div>
                                </div>
                                ) : ''}
                                
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>No. Seri Ijazah SD  <span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.no_ijazah_sd ? 'is-invalid' : ''}`}
                                            defaultValue={formData.no_ijazah_sd}
                                            onChange={(e) => handleChange(e)}
                                            name='no_ijazah_sd'
                                        />
                                        {errors.no_ijazah_sd && <p style={{ color: "red" }}>{errors.no_ijazah_sd}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>No. Seri Ijazah SMP  <span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.no_ijazah_smp ? 'is-invalid' : ''}`}
                                            defaultValue={formData.no_ijazah_smp}
                                            onChange={(e) => handleChange(e)}
                                            name='no_ijazah_smp'
                                        />
                                        {errors.no_ijazah_smp && <p style={{ color: "red" }}>{errors.no_ijazah_smp}</p>}

                                    </div>
                                </div>


                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>No. Akta Kelahiran<span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.no_akta ? 'is-invalid' : ''}`}
                                            defaultValue={formData.no_akta}
                                            onChange={(e) => handleChange(e)}
                                            name='no_akta'
                                        />
                                        {errors.no_akta && <p style={{ color: "red" }}>{errors.no_akta}</p>}

                                    </div>
                                </div>

                            </div>
                            <button type="button" className='btn  btn-danger' onClick={prevStep}>
                                Sebelumnya
                            </button>
                            <button type="button" className="btn btn-primary ml-2 bg-blue" onClick={nextStep}>Selanjutnya</button>

                        </div>
                    )}

                    {currentStep === 3 && (
                        <div className='card-box shadow-sm p-4'>
                            <div className="row">

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Alamat <span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.alamat ? 'is-invalid' : ''}`}
                                            defaultValue={formData.alamat}
                                            onChange={(e) => handleChange(e)}
                                            name='alamat'
                                        />
                                        {errors.alamat && <p style={{ color: "red" }}>{errors.alamat}</p>}

                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Provinsi <span className='text-danger'>*</span></label>
                                        <Select
                                            options={provinsiList.map(provinsi => ({ label: provinsi.name, value: provinsi.id }))}
                                            onChange={handleProvinsiChange}
                                            defaultValue={formData.provinsi}
                                            name='provinsi'
                                            placeholder={formData.provinsi ? formData.provinsi : "Pilih Provinsi"}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Kabupaten/Kota <span className='text-danger'>*</span></label>
                                        <Select
                                            options={kabupatenList.map(kabupaten => ({ label: kabupaten.name, value: kabupaten.id }))}
                                            onChange={handleKabupatenChange}
                                            defaultValue={formData.nama_kota_kabupaten}
                                            placeholder={formData.nama_kota_kabupaten ? formData.nama_kota_kabupaten : "Pilih Kabupaten"}
                                            name='nama_kota_kabupaten'
                                            isDisabled={isKabupatenDisabled}

                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Kecamatan <span className='text-danger'>*</span></label>
                                        <Select
                                            options={kecamatanList.map(kecamatan => ({ label: kecamatan.name, value: kecamatan.id }))}
                                            onChange={handleKecamatanChange}
                                            defaultValue={formData.kecamatan}
                                            placeholder={formData.kecamatan ? formData.kecamatan : "Pilih Kecamatan"}
                                            name='kecamatan'
                                            isDisabled={isKecamatanDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Kelurahan <span className='text-danger'>*</span></label>
                                        <Select
                                            options={kelurahanList.map(kelurahan => ({ label: kelurahan.name, value: kelurahan.id }))}
                                            onChange={handleKelurahanChange}
                                            defaultValue={formData.nama_kelurahan}
                                            placeholder={formData.nama_kelurahan ? formData.nama_kelurahan : "Pilih Provinsi"}
                                            name='nama_keluharan'
                                            isDisabled={isKelurahanDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>RT <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.rt ? 'is-invalid' : ''}`}
                                            defaultValue={formData.rt}
                                            onChange={(e) => handleChange(e)}
                                            name='rt'
                                        />
                                        {errors.rt && <p style={{ color: "red" }}>{errors.rt}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>RW <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.rw ? 'is-invalid' : ''}`}
                                            defaultValue={formData.rw}
                                            onChange={(e) => handleChange(e)}
                                            name='rw'
                                        />
                                        {errors.rw && <p style={{ color: "red" }}>{errors.rw}</p>}

                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Kode Pos <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.kode_pos ? 'is-invalid' : ''}`}
                                            defaultValue={formData.kode_pos}
                                            onChange={(e) => handleChange(e)}
                                            name='kode_pos'
                                        />
                                        {errors.kode_pos && <p style={{ color: "red" }}>{errors.kode_pos}</p>}

                                    </div>
                                </div>
                            </div>
                            <button type="button" className='btn  btn-danger' onClick={prevStep}>
                                Sebelumnya
                            </button>
                            <button type="button" className="btn btn-primary ml-2 bg-blue" onClick={nextStep}>Selanjutnya</button>
                        </div>
                    )}

                    {currentStep === 4 && (
                        <div className='card-box shadow-sm mt-4 p-4 mb-4'>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>No Kartu Keluarga (KK) <span className='text-danger'>*</span></label>
                                        <input
                                            type="number"
                                            className={`form-control ${errors.no_kk ? 'is-invalid' : ''}`}
                                            defaultValue={formData?.no_kk}
                                            onChange={(e) => handleChangeNumber(e,16) }
                                            name='no_kk'
                                        />
                                        {errors.no_kk && <p style={{ color: "red" }}>{errors.no_kk}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>NIK Peserta Didik <span className='text-danger'>*</span></label>
                                        <input
                                            type="number"
                                            className={`form-control ${errors.nik ? 'is-invalid' : ''}`}
                                            defaultValue={formData?.nik}
                                            onChange={(e) => handleChangeNumber(e,16) }
                                            name='nik'
                                        />
                                        {errors.nik && <p style={{ color: "red" }}>{errors.nik}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Nama Ayah  <span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.nama_ayah ? 'is-invalid' : ''}`}
                                            defaultValue={formData?.keluarga_id?.nama_ayah}
                                            onChange={(e) => handleChange(e)}
                                            name='nama_ayah'
                                        />
                                        {errors.nama_ayah && <p style={{ color: "red" }}>{errors.nama_ayah}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>NIK Ayah  <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.nik_ayah ? 'is-invalid' : ''}`}
                                            defaultValue={formData?.keluarga_id?.nik_ayah}
                                            onChange={(e) => handleChangeNumber(e,16)}
                                            name='nik_ayah'
                                        />
                                        {errors.nik_ayah && <p style={{ color: "red" }}>{errors.nik_ayah}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Tempat Lahir Ayah  <span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.tempat_lahir_ayah ? 'is-invalid' : ''}`}
                                            value={formData?.keluarga_id?.tempat_lahir_ayah}
                                            onChange={(e) => handleChange(e)}
                                            name='tempat_lahir_ayah'
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Tanggal Lahir Ayah  <span className='text-danger'>*</span></label>
                                        <input
                                            type='date'
                                            className={`form-control ${errors.tanggal_lahir_ayah ? 'is-invalid' : ''}`}
                                            defaultValue={formData?.keluarga_id?.tanggal_lahir_ayah}
                                            onChange={(e) => handleChange(e)}
                                            name='tanggal_lahir_ayah'
                                        />
                                        {errors.tanggal_lahir_ayah && <p style={{ color: "red" }}>{errors.tanggal_lahir_ayah}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Pendidikan Ayah  <span className='text-danger'>*</span></label>
                                        <select
                                            name='pendidikan_ayah'
                                            className={`form-select ${errors.agama ? 'is-invalid' : ''}`}
                                            defaultValue={formData?.keluarga_id?.pendidikan_ayah}
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Pendidikan</option>
                                            <option value="Tidak Sekolah">Tidak Sekolah</option>
                                            <option value="Putus SD">Putus SD</option>
                                            <option value="SD Sederajat">SD Sederajat</option>
                                            <option value="SMP Sederajat">SMP Sederajat</option>
                                            <option value="SMA Sederajat">SMA Sederajat</option>
                                            <option value="D1">D1</option>
                                            <option value="D2">D2</option>
                                            <option value="D3">D3</option>
                                            <option value="D4/S1"> D4/S1</option>
                                            <option value="S2">S2</option>
                                            <option value="S3">S3</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Pekerjaan Ayah  <span className='text-danger'>*</span></label>
                                        <select
                                            name='pekerjaan_ayah'
                                            className={`form-select ${errors.agama ? 'is-invalid' : ''}`}
                                            defaultValue={formData?.keluarga_id?.pekerjaan_ayah}
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Perkerjaan</option>
                                            <option value="Tidak Bekerja">Tidak Bekerja</option>
                                            <option value="Nelayan">Nelayan</option>
                                            <option value="Petani">Petani</option>
                                            <option value="Peternak">Peternak</option>
                                            <option value="PNS/TNI/POLRI"> PNS/TNI/POLRI</option>
                                            <option value="Karyawan Swasta"> Karyawan Swasta</option>
                                            <option value="Pedagang Kecil"> Pedagang Kecil</option>
                                            <option value="Pedagang Besar"> Pedagang Besar</option>
                                            <option value="Wiraswasta"> Wiraswasta</option>
                                            <option value="Wirausaha"> Wirausaha</option>
                                            <option value="Buruh"> Buruh</option>
                                            <option value="Pensiunan"> Pensiunan</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Penghasilan Ayah (perbulan)  <span className='text-danger'>*</span></label>
                                        <select
                                            name='penghasilan_ayah'
                                            className={`form-select ${errors.penghasilan_ayah ? 'is-invalid' : ''}`}
                                            defaultValue={formData?.keluarga_id?.penghasilan_ayah}
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Penghasilan</option>
                                            <option value="Rp. 500.000-Rp.999.999">Rp. 500.000-Rp.999.999</option>
                                            <option value="Rp. 1.000.000-Rp.1.999.999">Rp. 1.000.000-Rp.1.999.999</option>
                                            <option value="Rp.2.000.000-Rp.4.999.999">Rp.2.000.000-Rp.4.999.999</option>
                                            <option value="Rp.5.000.000-Rp.20.000.000">Rp.5.000.000-Rp.20.000.000</option>
                                            <option value="diatas Rp.20.000.000"> diatas Rp.20.000.000</option>
                                            <option value="Tidak Berpenghasilan"> Tidak Berpenghasilan</option>
                                        </select>
                                    </div>
                                </div>
                                
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>No HP Ayah <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.no_telp_ayah ? 'is-invalid' : ''}`}
                                            defaultValue={formData?.keluarga_id?.no_telp_ayah}
                                            onChange={(e) => handleChange(e)}
                                            name='no_telp_ayah'
                                        />
                                        {errors.no_telp_ayah && <p style={{ color: "red" }}>{errors.no_telp_ayah}</p>}

                                    </div>
                                </div>
                            </div>
                            <button type="button" className='btn  btn-danger' onClick={prevStep}>
                                Sebelumnya
                            </button>
                            <button type="button" className="btn btn-primary ml-2 bg-blue" onClick={nextStep}>Selanjutnya</button>
                        </div>
                    )}

                    {currentStep === 5 && (
                        <div className='card-box shadow-sm p-4 mt-4'>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Nama Ibu  <span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.nama_ayah ? 'is-invalid' : ''}`}
                                            defaultValue={formData?.keluarga_id?.nama_ibu}
                                            onChange={(e) => handleChange(e)}
                                            name='nama_ibu'
                                        />
                                        {errors.nama_ibu && <p style={{ color: "red" }}>{errors.nama_ibu}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>NIK Ibu  <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.nik_ibu ? 'is-invalid' : ''}`}
                                            defaultValue={formData?.keluarga_id?.nik_ibu}
                                            onChange={(e) => handleChangeNumber(e,16)}
                                            name='nik_ibu'
                                        />
                                        {errors.nik_ibu && <p style={{ color: "red" }}>{errors.nik_ibu}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Tempat Lahir Ibu  <span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.tempat_lahir_ibu ? 'is-invalid' : ''}`}
                                            value={formData?.keluarga_id?.tempat_lahir_ibu}
                                            onChange={(e) => handleChange(e)}
                                            name='tempat_lahir_ibu'
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Tanggal Lahir Ibu  <span className='text-danger'>*</span></label>
                                        <input
                                            type='date'
                                            className={`form-control ${errors.tanggal_lahir_ibu ? 'is-invalid' : ''}`}
                                            defaultValue={formData?.keluarga_id?.tanggal_lahir_ibu}
                                            onChange={(e) => handleChange(e)}
                                            name='tanggal_lahir_ibu'
                                        />
                                        {errors.tanggal_lahir_ibu && <p style={{ color: "red" }}>{errors.tanggal_lahir_ibu}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Pendidikan Ibu  <span className='text-danger'>*</span></label>
                                        <select
                                            name='pendidikan_ibu'
                                            className={`form-select ${errors.pendidikan_ibu ? 'is-invalid' : ''}`}
                                            defaultValue={formData?.keluarga_id?.pendidikan_ibu}
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Pendidikan</option>
                                            <option value="Tidak Sekolah">Tidak Sekolah</option>
                                            <option value="Putus SD">Putus SD</option>
                                            <option value="SD Sederajat">SD Sederajat</option>
                                            <option value="SMP Sederajat">SMP Sederajat</option>
                                            <option value="SMA Sederajat">SMA Sederajat</option>
                                            <option value="D1">D1</option>
                                            <option value="D2">D2</option>
                                            <option value="D3">D3</option>
                                            <option value="D4/S1"> D4/S1</option>
                                            <option value="S2">S2</option>
                                            <option value="S3">S3</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Pekerjaan Ibu  <span className='text-danger'>*</span></label>
                                        <select
                                            name='pekerjaan_ibu'
                                            className={`form-select ${errors.pekerjaan_ibu ? 'is-invalid' : ''}`}
                                            defaultValue={formData?.keluarga_id?.pekerjaan_ibu}
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Perkerjaan</option>
                                            <option value="Tidak Bekerja">Tidak Bekerja</option>
                                            <option value="Nelayan">Nelayan</option>
                                            <option value="Petani">Petani</option>
                                            <option value="Peternak">Peternak</option>
                                            <option value="PNS/TNI/POLRI"> PNS/TNI/POLRI</option>
                                            <option value="Karyawan Swasta"> Karyawan Swasta</option>
                                            <option value="Pedagang Kecil"> Pedagang Kecil</option>
                                            <option value="Pedagang Besar"> Pedagang Besar</option>
                                            <option value="Wiraswasta"> Wiraswasta</option>
                                            <option value="Wirausaha"> Wirausaha</option>
                                            <option value="Buruh"> Buruh</option>
                                            <option value="Pensiunan"> Pensiunan</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Penghasilan Ibu  <span className='text-danger'>*</span></label>
                                        <select
                                            name='penghasilan_ibu'
                                            className={`form-select ${errors.penghasilan_ibu ? 'is-invalid' : ''}`}
                                            defaultValue={formData?.keluarga_id?.penghasilan_ibu}
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Penghasilan</option>
                                            <option value="Rp. 500.000-Rp.999.999">Rp. 500.000-Rp.999.999</option>
                                            <option value="Rp. 1.000.000-Rp.1.999.999">Rp. 1.000.000-Rp.1.999.999</option>
                                            <option value="Rp.2.000.000-Rp.4.999.999">Rp.2.000.000-Rp.4.999.999</option>
                                            <option value="Rp.5.000.000-Rp.20.000.000">Rp.5.000.000-Rp.20.000.000</option>
                                            <option value="diatas Rp.20.000.000"> diatas Rp.20.000.000</option>
                                            <option value="Tidak Berpenghasilan"> Tidak Berpenghasilan</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>No HP Ibu <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.no_telp_ibu ? 'is-invalid' : ''}`}
                                            defaultValue={formData?.keluarga_id?.no_telp_ibu}
                                            onChange={(e) => handleChange(e)}
                                            name='no_telp_ibu'
                                        />
                                        {errors.no_telp_ibu && <p style={{ color: "red" }}>{errors.no_telp_ibu}</p>}

                                    </div>
                                </div>
                            </div>
                            <button type="button" className='btn  btn-danger' onClick={prevStep}>
                                Sebelumnya
                            </button>

                            <button type="button" className="btn btn-primary ml-2 bg-blue" onClick={nextStep}>Selanjutnya</button>

                        </div>
                    )}

                    {currentStep === 6 && (
                        <div className='card-box shadow-sm p-4 mb-4 mt-4'>
                            <div className='row'>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Ada Wali?</label>
                                        <div className="form-inline">
                                            <input type="radio" name="ada_wali" id="wali" className={'form-check'}
                                                onChange={(e) => handleChange(e)} defaultValue={'Ya'}
                                            /> Ya
                                        </div>
                                        <div className="form-inline">
                                            <input type="radio" name="ada_wali" id="wali" className={'form-check'}
                                                onChange={(e) => handleChange(e)} defaultValue={'Tidak'}
                                            /> Tidak
                                        </div>
                                        {/* <input
                                            type='text'
                                            className={`form-control ${errors.nama_wali ? 'is-invalid' : ''}`}
                                            defaultValue={formData?.keluarga_id?.nama_wali}
                                            onChange={(e) => handleChange(e)}
                                            name='nama_wali'
                                        /> */}
                                    </div>
                                </div>
                            </div>

                            {formData.ada_wali == 'Ya' ? (
                                <div className='row'>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Nama Wali</label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.nama_wali ? 'is-invalid' : ''}`}
                                                defaultValue={formData?.keluarga_id?.nama_wali}
                                                onChange={(e) => handleChange(e)}
                                                name='nama_wali'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>NIK Wali</label>
                                            <input
                                                type='number'
                                                className={`form-control ${errors.nik_wali ? 'is-invalid' : ''}`}
                                                defaultValue={formData?.keluarga_id?.nik_wali}
                                                onChange={(e) => handleChangeNumber(e,16)}
                                                name='nik_wali'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Jenis Kelamin</label>
                                            <select
                                                name='jk_wali'
                                                className={`form-select ${errors.jk_wali ? 'is-invalid' : ''}`}
                                                value={formData?.keluarga_id?.jk_wali}
                                                onChange={(e) => handleChange(e)}>
                                                {/* <option value="" disabled>{formData.jk_wali ? formData.jk_wali : "Pilih Jenis Kelamin"}</option> */}
                                                <option value="">Pilih Jenis Kelamin</option>
                                                <option value="P">P</option>
                                                <option value="L">L</option>
                                            </select>
                                            {errors.jk_wali && <p style={{ color: "red" }}>{errors.jk_wali}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Tempat Lahir Wali</label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.tempat_lahir_wali ? 'is-invalid' : ''}`}
                                                value={formData?.keluarga_id?.tempat_lahir_wali}
                                                onChange={(e) => handleChange(e)}
                                                name='tempat_lahir_wali'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Tanggal Lahir Wali</label>
                                            <input
                                                type='date'
                                                className={`form-control ${errors.tanggal_lahir_wali ? 'is-invalid' : ''}`}
                                                defaultValue={formData?.keluarga_id?.tanggal_lahir_wali}
                                                onChange={(e) => handleChange(e)}
                                                name='tanggal_lahir_wali'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Pendidikan Wali</label>
                                            <select
                                                name='pendidikan_wali'
                                                className={`form-select ${errors.pendidikan_wali ? 'is-invalid' : ''}`}
                                                defaultValue={formData?.keluarga_id?.pendidikan_wali}
                                                onChange={(e) => handleChange(e)}>

                                                <option value="">Pilih Pendidikan</option>
                                                <option value="Tidak Sekolah">Tidak Sekolah</option>
                                                <option value="Putus SD">Putus SD</option>
                                                <option value="SD Sederajat">SD Sederajat</option>
                                                <option value="SMP Sederajat">SMP Sederajat</option>
                                                <option value="SMA Sederajat">SMA Sederajat</option>
                                                <option value="D1">D1</option>
                                                <option value="D2">D2</option>
                                                <option value="D3">D3</option>
                                                <option value="D4/S1"> D4/S1</option>
                                                <option value="S2">S2</option>
                                                <option value="S3">S3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Pekerjaan Wali</label>
                                            <select
                                                name='pekerjaan_wali'
                                                className={`form-select ${errors.pekerjaan_wali ? 'is-invalid' : ''}`}
                                                defaultValue={formData?.keluarga_id?.pekerjaan_wali}
                                                onChange={(e) => handleChange(e)}>

                                                <option value="">Pilih Perkerjaan</option>
                                                <option value="Tidak Bekerja">Tidak Bekerja</option>
                                                <option value="Nelayan">Nelayan</option>
                                                <option value="Petani">Petani</option>
                                                <option value="Peternak">Peternak</option>
                                                <option value="PNS/TNI/POLRI"> PNS/TNI/POLRI</option>
                                                <option value="Karyawan Swasta"> Karyawan Swasta</option>
                                                <option value="Pedagang Kecil"> Pedagang Kecil</option>
                                                <option value="Pedagang Besar"> Pedagang Besar</option>
                                                <option value="Wiraswasta"> Wiraswasta</option>
                                                <option value="Wirausaha"> Wirausaha</option>
                                                <option value="Buruh"> Buruh</option>
                                                <option value="Pensiunan"> Pensiunan</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Penghasilan Wali</label>
                                            <select
                                                name='penghasilan_wali'
                                                className={`form-select ${errors.penghasilan_wali ? 'is-invalid' : ''}`}
                                                defaultValue={formData?.keluarga_id?.penghasilan_wali}
                                                onChange={(e) => handleChange(e)}>

                                                <option value="">Pilih Penghasilan</option>
                                                <option value="Rp. 500.000-Rp.999.999">Rp. 500.000-Rp.999.999</option>
                                                <option value="Rp. 1.000.000-Rp.1.999.999">Rp. 1.000.000-Rp.1.999.999</option>
                                                <option value="Rp.2.000.000-Rp.4.999.999">Rp.2.000.000-Rp.4.999.999</option>
                                                <option value="Rp.5.000.000-Rp.20.000.000">Rp.5.000.000-Rp.20.000.000</option>
                                                <option value="diatas Rp.20.000.000"> diatas Rp.20.000.000</option>
                                                <option value="Tidak Berpenghasilan"> Tidak Berpenghasilan</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>No HP Wali <span className='text-danger'>*</span></label>
                                            <input
                                                type='number'
                                                className={`form-control ${errors.no_telp_wali ? 'is-invalid' : ''}`}
                                                defaultValue={formData?.keluarga_id?.no_telp_wali}
                                                onChange={(e) => handleChange(e)}
                                                name='no_telp_wali'
                                            />
                                            {errors.no_telp_wali && <p style={{ color: "red" }}>{errors.no_telp_wali}</p>}

                                        </div>
                                    </div>
                                </div>
                            ) : ''}
                            <button type="button" className='btn  btn-danger' onClick={prevStep}>
                                Sebelumnya
                            </button>
                            <button type="button" className="btn btn-primary ml-2 bg-blue" onClick={nextStep}>Selanjutnya</button>
                        </div>
                    )}
                    
                    {currentStep === 7 && (
                        <div className='card-box shadow-sm p-4'>
                            <div className="row">

                                <div className="col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label>Persetujuan dan Kirim <span className='text-danger'>*</span></label>
                                        <div className="form-inline">
                                            <input type="hidden" name="status_data_diri" id="status_data_diri" className={'form-check mr-1'}
                                                onChange={(e) => handleChange(e)} value={'Konfirmasi'}
                                            /> 
                                            <input type="hidden" name="status_data_family" id="status_data_family" className={'form-check mr-1'}
                                                onChange={(e) => handleChange(e)} value={'Konfirmasi'}
                                            /> 
                                            <input type="checkbox" name="status_data_dokumen" id="status_data_dokumen" className={'form-check mr-1'}
                                                onChange={handleCheck} value={'Konfirmasi'} required={'required'}
                                            /> Dengan ini saya menyetujui data yang saya isi sesuai dengan data Ijazah SMP, Ijazah SD, Akta Kelahiran dan KK
                                        </div>
                                        {errors.status_data_diri && <p style={{ color: "red" }}>{errors.status_data_diri}</p>}

                                    </div>
                                </div>

                            </div>
                            <button type="button" className='btn  btn-danger' onClick={prevStep}>
                                Sebelumnya
                            </button>
                            <button type="submit" className='btn bg-blue text-white ml-3'>Kirim</button>
                        </div>
                    )}

                </form>
            </LayoutDashboard>
        </div >
    );
}
