import React, { createContext, useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { useContext } from 'react';
import GtkContext from './GtkContext';
import apiClient from '../axiosConfig';
export const DashboardContext = createContext("");

export const DashboardProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [dataBelumLengkap, setDataBelumLengkap] = useState(0);
    const [dataLengkap, setDataLengkap] = useState(0);
    const [dataTotal, setDataTotal] = useState(0);
    const [dataLainnya, setDataLainnya] = useState(0)

    const [AdminDataDiriLengkap, setAdminDataDiriLengkap] = useState(0);
    const [AdminDataFamilyLengkap, setAdminDataFamilyLengkap] = useState(0);
    const [AdminDataDokumenLengkap, setAdminDataDokumenLengkap] = useState(0);

    const [AdminDataDiriBelum, setAdminDataDiriBelum] = useState(0);
    const [AdminDataFamilyBelum, setAdminDataFamilyBelum] = useState(0);
    const [AdminDataDokumenBelum, setAdminDataDokumenBelum] = useState(0);

    const [AdminDataBelumLengkap, setAdminDataBelumLengkap] = useState(0);
    const [AdminDataSudahLengkap, setAdminDataSudahLengkap] = useState(0);

    const [RayonDataDiri, setRayonDataDiri] = useState({});
    const [RayonDataFamily, setRayonDataFamily] = useState({});
    const [RayonDataDokumen, setRayonDataDokumen] = useState({});

    const { gtk } = useContext(GtkContext)
    const fetchDataFromApi = async () => {
        try {
            setLoading(true);
            const incompleteDataCount = await apiClient.get(`${process.env.REACT_APP_API_ENDPOINT}/data-missing/${gtk.nama_rayon}`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            });
            const completeData = await apiClient.get(`${process.env.REACT_APP_API_ENDPOINT}/data-lengkap/${gtk.nama_rayon}`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            });
            setDataBelumLengkap(incompleteDataCount.data.incompleteDataCount)
            setDataLengkap(completeData.data.completeData)
            setDataLainnya(incompleteDataCount.data)

            const rayonDataDiri = await apiClient.get(`${process.env.REACT_APP_API_ENDPOINT}/count/data-diri/${gtk.nama_rayon}`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            });

            setDataTotal(rayonDataDiri.data.belum + rayonDataDiri.data.lengkap);

            setLoading(false);

        } catch (error) {
            console.log('Error fetching data from API');
        }
    };


    const AdminSudahLengkap = async () => {
        try {
            setLoading(true);
            const adminSudah = await apiClient.get(`${process.env.REACT_APP_API_ENDPOINT}/data-lengkap`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            });
            setAdminDataSudahLengkap(adminSudah?.data?.completeData)
            setLoading(false);
        } catch (error) {
            console.log('Error fetching data from API');
        }
    }

    const AdminBlumLengkap = async () => {
        try {
            setLoading(true);
            const adminBelum = await apiClient.get(`${process.env.REACT_APP_API_ENDPOINT}/data-missing`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            });
            setAdminDataBelumLengkap(adminBelum?.data?.incompleteDataCount)
            setLoading(false);
        } catch (error) {
            console.log('Error fetching data from API');
        }
    }

    const AdminDataDiri = async () => {
        try {
            setLoading(true);
            const adminDataDiri = await apiClient.get(`${process.env.REACT_APP_API_ENDPOINT}/count/data-diri`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            });
            // console.log(adminDataDiri)
            setAdminDataDiriLengkap(adminDataDiri.data.lengkap)
            setAdminDataDiriBelum(adminDataDiri.data.belum)
        } catch (error) {
            console.log(error)
        }
    }

    const AdminDataFamily = async () => {
        try {
            setLoading(true);
            const adminDataFamily = await apiClient.get(`${process.env.REACT_APP_API_ENDPOINT}/count/data-family`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            });
            // console.log(adminDataFamily)
            setAdminDataFamilyLengkap(adminDataFamily.data.lengkap)
            setAdminDataFamilyBelum(adminDataFamily.data.belum)
        } catch (error) {
            console.log(error)
        }
    }

    const AdminDataDokumen = async () => {
        try {
            setLoading(true);
            const adminDataDokumen = await apiClient.get(`${process.env.REACT_APP_API_ENDPOINT}/count/data-dokumen`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            });
            // console.log(adminDataDokumen)
            setAdminDataDokumenLengkap(adminDataDokumen.data.lengkap)
            setAdminDataDokumenBelum(adminDataDokumen.data.belum)
        } catch (error) {
            console.log(error)
        }
    }

    const RayonData = async () => {
        try {
            setLoading(true);
            const rayonDataDiri = await apiClient.get(`${process.env.REACT_APP_API_ENDPOINT}/count/data-diri/${gtk.nama_rayon}`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            });
            const rayonDataFamily = await apiClient.get(`${process.env.REACT_APP_API_ENDPOINT}/count/data-family/${gtk.nama_rayon}`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            });
            const rayonDataDokumen = await apiClient.get(`${process.env.REACT_APP_API_ENDPOINT}/count/data-dokumen/${gtk.nama_rayon}`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            });
            setRayonDataDiri(rayonDataDiri.data)
            setRayonDataFamily(rayonDataFamily.data)
            setRayonDataDokumen(rayonDataDokumen.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchDataFromApi();
        AdminBlumLengkap();
        AdminSudahLengkap();
        AdminDataDiri();
        AdminDataFamily();
        AdminDataDokumen();
        RayonData();
    }, [gtk])

    const state = {
        dataBelumLengkap,
        dataLengkap,
        dataTotal,
        dataLainnya,
        loading,
        AdminDataBelumLengkap,
        AdminDataSudahLengkap,
        AdminDataDiriLengkap,
        AdminDataFamilyLengkap,
        AdminDataDokumenLengkap,
        AdminDataDiriBelum,
        AdminDataFamilyBelum,
        AdminDataDokumenBelum,
        RayonDataDiri,
        RayonDataFamily,
        RayonDataDokumen,
    }
    return (
        <DashboardContext.Provider value={state}>
            {children}
        </DashboardContext.Provider>
    );
};

export default DashboardContext;


