import React, { createContext, useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { useContext } from 'react';
import AuthContext from './AuthContext';
import apiClient from '../axiosConfig';

export const AllStudentContext = createContext();

export const AllStudentProvider = ({ children }) => {
  const [allStudent, setAllStudent] = useState([]);
  const { user } = useContext(AuthContext);
  const [asalSmp, setAsalSmp] = useState([]) 
  

  const getAllStudent = async () => {
    try {
      const response = await apiClient.get(process.env.REACT_APP_API_ENDPOINT + '/student', {
        headers: {
          'ngrok-skip-browser-warning': 'true',
          'Authorization': `Bearer ${localStorage.getItem("token")}`  
        },
      });
      setAllStudent(response.data.students);

    } catch (error) {
      console.log("Server responded with error:", error.response.data);

    }

  };

  const getAsalSmp = async () => {
    try {
      const response = await apiClient.get(process.env.REACT_APP_API_ENDPOINT + '/data/asal_smp', {
        headers: {
          'ngrok-skip-browser-warning': 'true',
        },
      });
      setAsalSmp(response.data.data);
    } catch (error) {
      console.log("Server responded with error:", error.response.data);

    }
  };

  useEffect(() => {
    getAllStudent();
    getAsalSmp()
  }, [user]);

  const contextValue = {
    allStudent,
    asalSmp,
    getAllStudent
  };

  return (
    <AllStudentContext.Provider value={contextValue}>
      {children}
    </AllStudentContext.Provider>
  );
};

