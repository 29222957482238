import React, { createContext, useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import apiClient from '../axiosConfig';
export const AllGtkContext = createContext();
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT

export const AllGtkProvider = ({ children }) => {
    const navigate = useNavigate();
    const [allGtk, setAllGtk] = useState([]);
    const [allGtkCount, setAllGtkCount] = useState(0)

    const AllGetGtk = async () => {
        try {
            const response = await apiClient.get(apiEndpoint + "/gtk", {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            });
            setAllGtk(response.data?.gtks);
            setAllGtkCount(response.data?.total_data)
        } catch (error) {

           console.log(error)
        }
    };

    useEffect(() => {
        AllGetGtk();
    }, [])

    const state = {
        allGtk,
        allGtkCount,
        AllGetGtk
    }
    return (
        <AllGtkContext.Provider value={state}>
            {children}
        </AllGtkContext.Provider>
    );
};

export default AllGtkContext;


