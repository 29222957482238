import React, { createContext, useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import apiClient from '../axiosConfig';

export const RayonContext = createContext();


export const RayonProvider = ({ children }) => {
  const [rayons, setRombel] = useState([])
  const getRayons = async () => {
    try {
      const response = await apiClient.get(`${process.env.REACT_APP_API_ENDPOINT}/rayon`, {
        headers: {
          'ngrok-skip-browser-warning': 'true',
        },
      });
      setRombel(response?.data.rayon);
    } catch (error) {
      console.log("Terjadi kesalahan saat mengambil data Rombel:");
    }
  };

  useEffect(() => {
    getRayons();
  }, [])

  const state = {
    rayons,
    getRayons
  }
  return (
    <RayonContext.Provider value={state}>
      {children}
    </RayonContext.Provider>
  );
};

export default RayonContext;


