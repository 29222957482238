import axios from 'axios';
import React, { useContext } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import GelBreadcrumb from '../../../components/globalcomp/GelBreadcrumb';
import LayoutDashboard from '../../../layouts/LayoutDashboard';
import { CekSiswa } from '../data-diri/DataDiri/components/CekSiswa';
import { CekKeluarga } from '../data-diri/DataDiri/components/CekKeluarga';
import { CekSmp } from '../data-diri/DataDiri/components/CekSmp';
import AuthContext from '../../../context/AuthContext';
import Portfolio from '../data-diri/DataDiri/components/Portfolio';
import Sertifikat from '../data-diri/DataDiri/components/Sertfifkat';
import apiClient from '../../../axiosConfig';


export const StudentDetailPage = () => {
    const { id } = useParams();
    const { user } = useContext(AuthContext)
    const tabs = [
        { label: 'Siswa' },
        { label: 'Keluarga' },
        { label: 'Dokumen' },
        { label: 'Portfolio' },
        { label: 'Sertifikat' },
    ];
    const [activeTab, setActiveTab] = useState(0);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const [studentData, setStudentData] = useState({});

    useEffect(() => {
        apiClient.get(process.env.REACT_APP_API_ENDPOINT + `/student/${id}`, {
            headers: {
                'ngrok-skip-browser-warning': 'true',
            },
        }).then(res => {
            setStudentData(res.data.student);
        }).catch(err => {
            console.log('Error fetching data:', err)
        });
        setStudentData()
    }, [id]);

    return (
        <div>

            <LayoutDashboard >
                {
                    user.role === "guru" ? <GelBreadcrumb satu="Data siswa" dua={`Detail Data ${studentData?.nama}`} urlSatu="/dashboard/pembimbing/siswa" id={id} /> :
                        <GelBreadcrumb satu="Data siswa" dua={`Detail Data ${studentData?.nama}`} urlSatu="/admin/dashboard/student" id={id} />
                }
                

                <div className='card-box pt-3 px-3 shadow-sm mb-3' >
                    <ul className="nav nav-pills nav-fill ">
                        {tabs.map((tab, index) => (
                            <li className="nav-item cursor-pointer" key={index}>
                                <p className={`nav-link pointer ${activeTab === index
                                    ? 'active'
                                    : ''
                                    }`} aria-current="page" onClick={() => handleTabClick(index)}> {tab.label}</p>
                            </li>
                        ))}

                    </ul>
                </div>
                <div className='card-box p-4 shadow-sm'>

                    {studentData ? (
                        <>
                            <div className="tab-content">
                                {activeTab === 0 && <div>
                                    <CekSiswa studentData={studentData} />
                                </div>}
                                {activeTab === 1 && <div>
                                    <CekKeluarga studentData={studentData} />
                                </div>}

                                {activeTab === 2 && <div>
                                    <CekSmp studentData={studentData} />
                                </div>}

                                {activeTab === 3 && <div>
                                    <Portfolio studentData={studentData} />
                                </div>}
                                {activeTab === 4 && <div>
                                    <Sertifikat studentData={studentData} />
                                </div>}

                            </div>
                        </>
                    ) : (<>Loading..</>)}
                    <p></p>
                </div>
            </LayoutDashboard>
        </div>
    );
}
