import React, { useState, useEffect, useContext } from 'react';
import LayoutDashboard from '../../layouts/LayoutDashboard';
import GelBreadcrumb from '../../components/globalcomp/GelBreadcrumb';
import GtkContext from '../../context/GtkContext';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import apiClient from '../../axiosConfig';

export const DetailDashboardGuruPage = () => {
  const { tipe, status } = useParams();
  const { gtk } = useContext(GtkContext);
  const { user } = useContext(AuthContext);
  const [dataLengkap, setDataLengkap] = useState([]);
  const endpoint = (tipe === 'diri') ? 'count/data-diri' : ((tipe == 'keluarga') ? 'count/data-family' : 'count/data-dokumen');
  // const endpoint = status === 'telah' ? 'data-lengkap' : 'data-missing';
  // const stat = status === 'telah' ? 'data-lengkap' : 'data-missing';

  const fetchDataFromApi = async () => {
    try {
      const url = (user.role === 'admin') ? `${process.env.REACT_APP_API_ENDPOINT}/${endpoint}` : `${process.env.REACT_APP_API_ENDPOINT}/${endpoint}/${gtk.nama_rayon}`;

      const completeData = await apiClient.get(url, {
        headers: {
          'ngrok-skip-browser-warning': 'true', 
        },
      });

      if (user.role === 'admin') {
        if (status === 'telah') {
          const resData = completeData.data.data.lengkap
          setDataLengkap(resData);
        } else {
          const resData = completeData.data.data.belum
          setDataLengkap(resData);
        }
      } else if (user.role === 'guru') {
        if (status === 'telah') {
          const resData = completeData.data.dataLengkap
          setDataLengkap(resData);
        } else {
          const resData = completeData.data.dataBelum
          setDataLengkap(resData);
        }
      }

      console.log(completeData.data)
    } catch (error) {
      console.error('Error fetching data from API:', error);
      throw error;
    }
  };

  useEffect(() => {
    fetchDataFromApi();
    document.title = "Data Lengkap | Sidata";
  }, [gtk, user, status]);

  return (
    <div>
      <LayoutDashboard>

        {status === 'telah' ? (
          <GelBreadcrumb satu="Dashboard" dua="Sudah Mengisi Data" urlSatu="/dashboard/pembimbing" />

        ) : (<GelBreadcrumb satu="Dashboard" dua="Belum Mengisi Data" urlSatu="/dashboard/pembimbing" />
        )}
        <div className='card-box shadow-sm p-4'>
          {
            user.role === 'guru' ? (
              <table className="table">
                <thead className='thead-dark'>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nama</th>
                    <th scope="col">Rombel</th>
                    <th scope="col">NIS</th>
                    <th scope="col">No Telephone</th>
                    {/* <th scope="col">Aksi</th> */}
                  </tr>
                </thead>
                <tbody>
                  {dataLengkap.map((item, index) => (
                    <tr key={item.id}>
                      <td scope="row">{index + 1}</td>
                      <td>{item.nama}</td>
                      <td>{item.rombel}</td>
                      <td>{item.nis}</td>
                      <td>{item.no_telp}</td>
                      {/* <td className='d-flex'> */}
                      {/* <WhatsAppIntegration /> */}
                      {/* </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table className="table">
                <thead className='thead-dark'>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">NIS</th>
                    <th scope="col">Nama</th>
                    <th scope="col">Rayon</th>
                    <th scope="col">Rombel</th>
                  </tr>
                </thead>
                <tbody>
                  {dataLengkap.map((item, index) => (
                    <tr key={item.id}>
                      <td scope="row">{index + 1}</td>
                      <td>{item.nis}</td>
                      <td>{item.nama}</td>
                      <td>{item.rayon}</td>
                      <td>{item.rombel}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )
          }
        </div>
      </LayoutDashboard>
    </div>
  );
};
