import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../../../../context/AuthContext';
import { FaRegCheckCircle, FaSpinner, FaTruckLoading } from "react-icons/fa";
import Swal from 'sweetalert2';
import axios from 'axios';
import apiClient from '../../../../../axiosConfig';

export const CekSiswa = ({ studentData }) => {
    const [cekSiswa, setCekSiswa] = useState({});
    const { user } = useContext(AuthContext);
    
    useEffect(() => {
        setCekSiswa(studentData);
    }, [studentData]);

    const handleStatusUbah = async () => {
        const result = await Swal.fire({
            title: "Konfirmasi",
            text: "Apakah Anda yakin memberi akses edit data?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText: "Tidak",
        });

        if (result.isConfirmed) {
            let data = {
                status_data_diri: 'Pending',
                status_data_family: 'Pending',
                status_data_dokumen: 'Pending',
            };

            try {
                await apiClient.post(`${process.env.REACT_APP_API_ENDPOINT}/change-status/${cekSiswa._id}`, data);
                Swal.fire({
                    title: "Sukses!",
                    text: "Berhasil Membuka Edit Data Diri",
                    icon: "success",
                }).then(() => {
                    window.location.reload()
                });
            } catch (error) {
                console.log(error, "error ini");
            }
        }
    };

    return (

        <div className="pd-20 mb-30">
        
            {user.role === "student" ? (
                cekSiswa?.nisn &&
                    cekSiswa?.no_telp &&
                    cekSiswa.tempat_lahir &&
                    cekSiswa.tanggal_lahir &&
                    cekSiswa.jk &&
                    cekSiswa.agama &&
                    cekSiswa.tb &&
                    cekSiswa.bb &&
                    cekSiswa.gol_darah &&
                    cekSiswa.anak_ke &&
                    cekSiswa.asal_smp &&
                    cekSiswa.no_ijazah_smp &&
                    cekSiswa.no_akta &&
                    cekSiswa.no_ijazah_sd ? (
                    cekSiswa.status_data_diri === "Pending" ? (
                        <div className="alert alert-info alert-dismissible fade show" role="alert">
                            <strong>Data Masih Pending!</strong> Pembimbing siswa akan mengecek datamu terlebih dahulu
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                    ) : (
                        ""
                    )
                ) : (
                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                        <strong>Data Belum Lengkap!</strong> Segera Lengkapi Data
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                )
            ) : ""}

            <div className="clearfix">
                <div className="pull-left">
                    <h4 className="text-blue h4">Data Diri Siswa</h4>
                    {user.role === 'student' && <p className="mb-30 text-waring">Lengkapi Data Jika Masih Kosong</p>}
                </div>
                {user.role === 'student' ? (
                    <div className="pull-right">
                        {studentData?.status_data_diri === "Konfirmasi" ? (
                            <button
                                className="btn gap-2 btn-success"
                                disabled>
                                Menunggu Verifikasi
                                <FaSpinner className='ml-2' />
                            </button>
                            
                            // <Link to="/dashboard/update/data-siswa">
                            //     <button className='btn btn-success py-2 px-4' style={{ 'fontSize': "18px", 'fontWeight': '600' }}>
                            //         Lengkapi Data Diri
                            //     </button>
                            // </Link>
                        ) : (studentData?.status_data_diri === 'Verifikasi' ? (
                            <button
                                className="btn gap-2 btn-success"
                                disabled>
                                Diverifikasi
                                <FaRegCheckCircle className='ml-2' />
                            </button>
                        ) : (
                            <Link to="/dashboard/update/data-siswa">
                                <button className='btn btn-success py-2 px-4' style={{ 'fontSize': "18px", 'fontWeight': '600' }}>
                                    Lengkapi Data Diri
                                </button>
                            </Link>
                        ))}
                    </div>
                ) : (
                    user.role === 'admin' || user.role === 'guru' ? (
                        (cekSiswa.status_data_diri === 'Konfirmasi' || cekSiswa.status_data_family === 'Konfirmasi' || cekSiswa.status_data_dokumen === 'Konfirmasi') || (cekSiswa.status_data_diri === 'Verifikasi' || cekSiswa.status_data_family === 'Verifikasi' || cekSiswa.status_data_dokumen === 'Verifikasi') ? (
                            <div className="pull-right mb-2">
                                <button onClick={handleStatusUbah} className='btn btn-primary bg-blue py-2 px-4' style={{ 'fontSize': "18px", 'fontWeight': '600' }}>
                                    Beri Akses Ubah Data Diri
                                </button>
                            </div>
                        ) : ''
                    ) : ''
                )}

            </div>
            <div className="row siswa">
                <div className="col-md-12">
                    <table className="table table-bordered table-hover">
                        <tbody>
                            <TableRow label="NIS" value={cekSiswa.nis} />
                            <TableRow label="NISN" value={cekSiswa.nisn ? cekSiswa.nisn : '-'} />
                            <TableRow label="Nama Lengkap" value={cekSiswa.nama} />
                            <TableRow label="Email" value={cekSiswa.email} />
                            <TableRow label="No HP" value={cekSiswa.no_telp ? cekSiswa.no_telp : '-'} />
                            <TableRow label="Tempat Lahir" value={cekSiswa.tempat_lahir ? cekSiswa.tempat_lahir : '-'} />
                            <TableRow label="Tanggal Lahir" value={cekSiswa.tanggal_lahir ? cekSiswa.tanggal_lahir : '-'} />
                            <TableRow label="Jenis Kelamin" value={cekSiswa.jk ? cekSiswa.jk : '-'} />
                            <TableRow label="Rombel" value={cekSiswa.rombel ? cekSiswa.rombel : '-'} />
                            <TableRow label="Rayon" value={cekSiswa.rayon ? cekSiswa.rayon : '-'} />
                            <TableRow label="Agama" value={cekSiswa.agama ? cekSiswa.agama : '-'} />
                            <TableRow label="Anak Ke" value={cekSiswa?.anak_ke ? cekSiswa?.anak_ke : "-"} />
                            <TableRow label="Tinggi Badan (TB)" value={cekSiswa.tb ? cekSiswa.tb : '-'} />
                            <TableRow label="Berat Badan (BB)" value={cekSiswa.bb ? cekSiswa.bb : '-'} />
                            <TableRow label="Golongan Darah" value={cekSiswa.gol_darah ? cekSiswa.gol_darah : '-'} />
                            <TableRow label="Asal SMP" value={cekSiswa.asal_smp ? (cekSiswa.asal_smp == 'Lainnya' ? cekSiswa.ket_asal_smp : cekSiswa.asal_smp) : '-'} />
                            <TableRow label="No. Seri Ijazah SD" value={cekSiswa.no_ijazah_sd ? cekSiswa.no_ijazah_sd : '-'} />
                            <TableRow label="No. Seri Ijazah SMP" value={cekSiswa.no_ijazah_smp ? cekSiswa.no_ijazah_smp : '-'} />
                            <TableRow label="Alamat Jalan" value={cekSiswa.alamat ? cekSiswa.alamat : '-'} />
                            <TableRow label="RT" value={cekSiswa.rt ? cekSiswa.rt : '-'} />
                            <TableRow label="RW" value={cekSiswa.rw ? cekSiswa.rw : '-'} />
                            <TableRow label="Provinsi" value={cekSiswa.provinsi ? cekSiswa.provinsi : '-'} />
                            <TableRow label="Kelurahan" value={cekSiswa.nama_kelurahan ? cekSiswa.nama_kelurahan : '-'} />
                            <TableRow label="Kecamatan" value={cekSiswa.kecamatan ? cekSiswa.kecamatan : '-'} />
                            <TableRow label="Nama Kota Kabupaten" value={cekSiswa.nama_kota_kabupaten ? cekSiswa.nama_kota_kabupaten : '-'} />
                            <TableRow label="Kode Pos" value={cekSiswa.kode_pos ? cekSiswa.kode_pos : '-'} />
                            <TableRow label="Transportasi" value={cekSiswa.transportasi ? (cekSiswa.transportasi === 'Lainnya' ? cekSiswa.ket_transportasi : cekSiswa.transportasi) : '-'} />
                            <TableRow label="Nama Saudara" value={cekSiswa.nama_referensi ? cekSiswa.nama_referensi : '-'} />
                            <TableRow label="Rayon Saudara" value={cekSiswa.referensi_rayon ? cekSiswa.referensi_rayon : '-'} />
                            <TableRow label="Apakah Menerima Beasiswa?" value={cekSiswa.referensi_penerima_beasiswa ? cekSiswa.referensi_penerima_beasiswa : '-'} />
                            {/* <TableRow label="Alamat Jalan" value={cekSiswa.alamat ? cekSiswa.alamat : '-'} />
                            <TableRow label="RT" value={cekSiswa.rt ? cekSiswa.rt : '-'} />
                            <TableRow label="RW" value={cekSiswa.rw ? cekSiswa.rw : '-'} />
                            <TableRow label="Kode Pos" value={cekSiswa.kode_pos ? cekSiswa.kode_pos : '-'} /> */}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const TableRow = ({ label, value }) => (
    <tr>
        <th style={{ fontSize: '18px', width: "250px" }}>{label}</th>
        <td style={{ fontSize: '18px' }}> {value}</td>
    </tr>
);
