import React, { useContext, useState } from 'react';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import StudentContext from '../../../../context/StudentContext';
import apiClient from '../../../../axiosConfig';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const EditDataSertifikatPage = () => {
    const navigate = useNavigate();
    const { student, getStudent } = useContext(StudentContext);

    const [formData, setFormData] = useState({
        nama_acara: "",
        nama_penyelenggara: "",
        tanggal_kegiatan: "",
        tingkat: "",
        ket_tingkat: "",
        sertifikat: null,
        juara: "",
        dokumentasi: ""
    });

    const [errors, setErrors] = useState({});

    const validateFile = (file, acceptedFormats, maxSize) => {
        const allowedFormats = new RegExp(`(${acceptedFormats.join('|')})$`, 'i');
        if (!file) return 'Wajib diisi';
        if (!allowedFormats.test(file.name)) return 'Format file tidak valid. Harap pilih file JPG atau PDF.';
        if (file.size > maxSize) return 'Ukuran file terlalu besar. Maksimal 2 MB.';
        return '';
    };

    const handleChange = (e) => {
        const { name, files } = e.target;
        let errorMessage = ''
        for (let index = 0; index < files.length; index++) {
            // const element = files[index];
            errorMessage = validateFile(files[index], ['jpg', 'jpeg', 'pdf', 'png', 'rar', 'zip'], 2 * 1024 * 1024)
            
        }
        // Object.entries(files).map(([key, value]) => (
        //     errorMessage = validateFile(value, ['jpg', 'jpeg', 'pdf', 'png', 'rar', 'zip'], 2 * 1024 * 1024)
        // ))

        // let file = el;
        // let errorMessage = validateFile(file, ['jpg', 'jpeg', 'pdf', 'rar', 'zip'], 2 * 1024 * 1024);

        setFormData((prevData) => ({ ...prevData, [name]: files }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formErrors = Object.fromEntries(
            Object.entries(formData.sertifikat).map(([key, value]) => [key, validateFile(value, ['jpg', 'jpeg', 'pdf', 'png', 'rar', 'zip'], 2 * 1024 * 1024)])
        );

        if (Object.values(formErrors).some((error) => error !== '')) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            Swal.fire({
                title: 'Loading...',
                text: 'Sedang memproses, harap tunggu.',
                allowOutsideClick: false,
                showConfirmButton: false,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            try {
                const data = new FormData();
                Object.entries(formData).forEach(([key, value]) => {
                    data.append(key, value);
                });

                // console.log(formData.dokumentasi[0])

                data.append("dokumentasi", formData.dokumentasi[0])
                // lengthPort = formData.sertifikat.length
                
                for (let index = 0; index < formData.sertifikat.length; index++) {
                    // const element = formData.sertifikat[index];
                    
                    data.append("sertifikat", formData.sertifikat[index])
                    // console.log('ser', data.append("sertifikat", formData.sertifikat[index]))
                }

                // console.log(port)

                // data.append('sertifikat', formData.sertifikat[0])
                // Object.entries(formData.sertifikat).forEach(([key, value]) => {
                //     data.append('file'+key, value);
                // });


                // for (let pair of data.entries()) {
                //     console.log(pair[0] + ', ' + pair[1]);
                // }

                // console.log(data)

                const response = await apiClient.post(`${apiEndpoint}/upload/sertifikat/${student?._id}`, data);
                // console.log(response, "ini data response");
                getStudent();
                Swal.fire({
                    title: 'Success!',
                    text: 'Dokumen berhasil diunggah.',
                    icon: 'success',
                }).then(() => navigate('/dashboard/data-diri'));
            } catch (error) {
                console.error(error);
                Swal.fire({
                    title: 'Error!',
                    text: 'Gagal mengunggah data. Silakan coba lagi.',
                    icon: 'error',
                });
            }
        }
    };

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Data Siswa" dua="Edit Data Sertifikat" urlSatu="/dashboard/data-diri" />
                <div className='card-box shadow-sm p-4'>
                    <form onSubmit={handleSubmit} encType='multipart/form-data'>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Nama Lomba <span className='text-danger'>*</span></label>
                                    <input
                                        type='text'
                                        className={`form-control ${errors.nama_acara ? 'is-invalid' : ''}`}
                                        defaultValue={formData?.nama_acara}
                                        name='nama_acara'
                                        onChange={e => setFormData({...formData, nama_acara: e.target.value})}
                                    />
                                    {errors.nama_acara && <p style={{ color: "red" }}>{errors.nama_acara}</p>}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Nama Penyelenggara <span className='text-danger'>*</span></label>
                                    <input
                                        type='text'
                                        className={`form-control ${errors.nama_penyelenggara ? 'is-invalid' : ''}`}
                                        defaultValue={formData?.nama_penyelenggara}
                                        name='nama_penyelenggara'
                                        onChange={e => setFormData({...formData, nama_penyelenggara: e.target.value})}
                                    />
                                    {errors.nama_penyelenggara && <p style={{ color: "red" }}>{errors.nama_penyelenggara}</p>}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Tanggal Penyelenggaraan <span className='text-danger'>*</span></label>
                                    <input
                                        type='date'
                                        className={`form-control ${errors.tanggal_kegiatan ? 'is-invalid' : ''}`}
                                        defaultValue={formData?.tanggal_kegiatan}
                                        name='tanggal_kegiatan'
                                        onChange={e => setFormData({...formData, tanggal_kegiatan: e.target.value})}
                                    />
                                    {errors.tanggal_kegiatan && <p style={{ color: "red" }}>{errors.tanggal_kegiatan}</p>}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Juara <small className='text-secondary'>(Peserta, 1,2,3, Lainnya)</small></label>
                                    <input
                                        type='text'
                                        className={`form-control ${errors.juara ? 'is-invalid' : ''}`}
                                        defaultValue={formData?.juara}
                                        name='juara'
                                        onChange={e => setFormData({...formData, juara: e.target.value})}
                                    />
                                    {errors.juara && <p style={{ color: "red" }}>{errors.juara}</p>}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Tingkat</label>
                                    <select
                                        className={`form-select ${errors.ket_tingkat ? 'is-invalid' : ''}`}
                                        defaultValue={formData.ket_tingkat} 
                                        name="tingkat" id="tingkat" onChange={e => setFormData({...formData, tingkat: e.target.value})}>
                                        <option value="">Pilih Tingkat</option>
                                        <option value="Kecamatan">Kecamatan</option>
                                        <option value="Kota/Kabupaten">Kota/Kabupaten</option>
                                        <option value="Provinsi">Provinsi</option>
                                        <option value="Nasional">Nasional</option>
                                        <option value="Internasional">Internasional</option>
                                        <option value="Lainnya">Lainnya</option>
                                    </select>
                                    {errors.tingkat && <p style={{ color: "red" }}>{errors.tingkat}</p>}
                                </div>
                            </div>

                            {formData.tingkat == 'Lainnya' ? (
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Keterangan Tingkat <span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    className={`form-control ${errors.tingkat ? 'is-invalid' : ''}`}
                                                    defaultValue={formData.ket_tingkat}
                                                    onChange={(e) => setFormData({...formData, ket_tingkat: e.target.value})}
                                                    name='ket_tingkat'
                                                />
                                        </div>
                                    </div>
                                </div>
                                ) : ''}

                            <div className="col-md-6 col-sm-12">
                                <div className="mb-3">
                                    <label htmlFor={`formFile-dokumentasi`} className="form-label text-capitalize">
                                        Foto Kegiatan Lomba <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        className={`form-control ${errors.dokumentasi ? 'is-invalid' : ''}`}
                                        type="file"
                                        id={`formFile-dokumentasi`}
                                        name={'dokumentasi'}
                                        onChange={handleChange}
                                    />
                                    {errors.dokumentasi && <p className='text-danger'>{errors.dokumentasi}</p>}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="mb-3">
                                    <label htmlFor={`formFile-sertifikat`} className="form-label text-capitalize">
                                        Sertifikat <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        className={`form-control ${errors['sertifikat'] ? 'is-invalid' : ''}`}
                                        type="file"
                                        id={`formFile-sertifikat`}
                                        name={'sertifikat'}
                                        onChange={handleChange}
                                        multiple
                                    />
                                    {errors['sertifikat'] && <p className='text-danger'>{errors['sertifikat']}</p>}
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Simpan</button>
                    </form>
                </div>
            </LayoutDashboard>
        </div>
    );
};
