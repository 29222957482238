import Swal from 'sweetalert2';
import apiClient from '../axiosConfig';

const importService = async (file, fieldData, apiEndpointImport, allGetData) => {
  const confirmResult = await Swal.fire({
    title: 'Apakah Anda yakin?',
    text: 'Anda akan mengimport data ini!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Ya, Import!',
  });

  if (confirmResult.isConfirmed) {
    try {
      Swal.fire({
        title: 'Sedang Mengimport...',
        text: 'Mohon tunggu',
        icon: 'info',
        allowOutsideClick: () => !Swal.isLoading(),
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      if (file[0]) {
        console.log('ini udah di submit', file[0]);
        const formDataToSend = new FormData();
        formDataToSend.append(fieldData, file[0]);
        const response = await apiClient.post(apiEndpointImport, formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          timeout: 60000, // Menambahkan waktu timeout (misalnya 60 detik)
        });
        
        
        console.log(response)

        if (response.data) {
          allGetData();
          Swal.fire({
            title: 'Sukses!',
            text: 'Data Berhasil Diimport',
            icon: 'success',
          });
        } else {
          const errorMessage = response.data?.message || 'Terjadi kesalahan saat mengimport data!';
          console.error(errorMessage);
          Swal.fire({
            title: 'Oops...',
            text: errorMessage,
            icon: 'error',
          });
        }
      } else {
        console.log('File is undefined');
      }
    } catch (error) {
      console.log(error, 'Kenapa ini');
      const errorMessage = error.response?.data?.message || 'Terjadi kesalahan saat mengimport data!';
      console.error(errorMessage);
      Swal.fire({
        title: 'Oops...',
        text: errorMessage,
        icon: 'error',
      });
    } finally {
      Swal.hideLoading();
    }
    }
  }
    

export default importService;