import axios from "axios";
import apiClient from "../axiosConfig";

// excelExport.js
const exportToExcel = (data, fileName) => {
  const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

const fetchDataFromApi = async (apiEndpoint) => {
  try {
    const response = await apiClient.get(apiEndpoint, {
      headers: {
        'ngrok-skip-browser-warning': 'true',
      },
      responseType: 'blob',
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data from API:', error);
    throw error;
  }
};

const handleExportClick = async (apiEndpoint, fileName) => {
  try {
    const apiData = await fetchDataFromApi(apiEndpoint);
    exportToExcel(apiData, fileName);
  } catch (error) {
    console.error('Error exporting data:', error);
  }
};

export default handleExportClick;
