import React, { createContext, useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { useContext } from 'react';
import AuthContext from './AuthContext';
import apiClient from '../axiosConfig';

export const StudentContext = createContext();

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const StudentProvider = ({ children }) => {
  const [student, setStudent] = useState([]);
  const { user } = useContext(AuthContext);

  const getStudent = async () => {
    try {
      const response = await apiClient.get(`${apiEndpoint}/dashboard/student/${user?.userId}`, {
        headers: {
          'ngrok-skip-browser-warning': 'true',
        },
      });
      console.log(response.data?.student)
      setStudent(response?.data?.student || []);
    } catch (error) {
      console.log("Terjadi kesalahan pada server");
    } 

  };

  useEffect(() => {
      getStudent();
  }, [user])
  
  const state = {
    student,
    getStudent
  } 
  return (
    <StudentContext.Provider value={state}>
      {children}
    </StudentContext.Provider>
  );
};

export default StudentContext;


