import React, { useState } from 'react';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const LengkapiData = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    'nama_ibu': "",
    "alamat": "",
    "rt": "",
    "rw": "",
    "provinsi": "",
    "kota_kab": "",
    "kecamatan": "",
    "nama_kelurahan": "",
    "no_kk": "",
    "kode_pos": "",
    "npwp": "",
    "nama_wajib_pajak": "",
    "kewarganegaraan": "",
    "status_perkawinan": "",
    "nama_istri_suami": "",
    "nip_istri_suami": "",
    "pekerjaan_istri_suami": "",
    "no_telp_rumah": "",
    "bb": "",
    "tb": "",
    "gol_darah": "",
  });

  const [errors, setErrors] = useState({});

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`${apiEndpoint}/gtk/${id}`, {
  //         headers: {
  //           'ngrok-skip-browser-warning': 'true',
  //         },
  //       });
  //       setFormData(response.data.gtk);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: value ? '' : 'Wajib diisi' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, String(value).trim() ? '' : 'Wajib diisi'])
    );

    if (Object.values(formErrors).some((error) => error)) {
      setErrors(formErrors);
      return;
    }

    const result = await Swal.fire({
      title: 'Konfirmasi',
      text: 'Apakah Anda yakin data sudah benar?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    });

    if (result.isConfirmed) {
      try {
        await axios.put(`${apiEndpoint}/gtk/update/${id}`, formData, {});
        Swal.fire({
          title: 'Sukses!',
          text: 'Berhasil Update Data',
          icon: 'success',
        }).then(() => navigate('/dashboard/pembimbing/data-diri/show'));
      } catch (error) {
        console.log(error, "error");
      }
    }
  };
  


return (
    <div>
      <LayoutDashboard>
        <GelBreadcrumb satu="Dashboard" dua="Lengkapi Data Gtk" tiga="Data Gtk" />

        <div className='card-box shadow-sm p-4 mb-30'>
          <form onSubmit={handleSubmit}>
            <div className="row mt-3">
              <div className="col-md-6 mb-10">
              <label htmlFor='nama_ibu' className='form-label'>Nama Ibu :</label>
                <input type='text' name='nama_ibu'
                  className={`form-control ${errors.nama_ibu ? 'is-invalid' : ''}`}
                  value={formData.nama_ibu}
                  onChange={(e) => handleChange(e)} />
                {errors.nama_ibu && <p style={{ color: "red" }}>{errors.nama_ibu}</p>}
              </div>
              <div className="col-md-6 mb-10">
              <label htmlFor='alamat' className='form-label'>Alamat :</label>
                <input type='text' name='alamat'
                  className={`form-control ${errors.alamat ? 'is-invalid' : ''}`}
                  value={formData.alamat}
                  onChange={(e) => handleChange(e)} />
                {errors.alamat && <p style={{ color: "red" }}>{errors.alamat}</p>}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 mb-10">
              <label htmlFor='rt' className='form-label'>Rt :</label>
                <input type='text' name='rt'
                  className={`form-control ${errors.rt ? 'is-invalid' : ''}`}
                  value={formData.rt}
                  onChange={(e) => handleChange(e)} />
                {errors.rt && <p style={{ color: "red" }}>{errors.rt}</p>}
              </div>
              <div className="col-md-6 mb-10">
              <label htmlFor='rw' className='form-label'>Rw :</label>
                <input type='text' name='rw'
                  className={`form-control ${errors.rw ? 'is-invalid' : ''}`}
                  value={formData.rw}
                  onChange={(e) => handleChange(e)} />
                {errors.rw && <p style={{ color: "red" }}>{errors.rw}</p>}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 mb-10">
              <label htmlFor='provinsi' className='form-label'>Provinsi :</label>
                <input type='text' name='provinsi'
                  className={`form-control ${errors.provinsi ? 'is-invalid' : ''}`}
                  value={formData.provinsi}
                  onChange={(e) => handleChange(e)} />
                {errors.provinsi && <p style={{ color: "red" }}>{errors.provinsi}</p>}
              </div>
              <div className="col-md-6 mb-10">
              <label htmlFor='kota_kab' className='form-label'>Kota / Kabupaten :</label>
                <input type='text' name='kota_kab'
                  className={`form-control ${errors.kota_kab ? 'is-invalid' : ''}`}
                  value={formData.kota_kab}
                  onChange={(e) => handleChange(e)} />
                {errors.kota_kab && <p style={{ color: "red" }}>{errors.kota_kab}</p>}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 mb-10">
              <label htmlFor='kecamatan' className='form-label'>Kecamatan :</label>
                <input type='text' name='kecamatan'
                  className={`form-control ${errors.kecamatan ? 'is-invalid' : ''}`}
                  value={formData.kecamatan}
                  onChange={(e) => handleChange(e)} />
                {errors.kecamatan && <p style={{ color: "red" }}>{errors.kecamatan}</p>}
              </div>
              <div className="col-md-6 mb-10">
              <label htmlFor='nama_kelurahan' className='form-label'>Nama Kelurahan :</label>
                <input type='text' name='nama_kelurahan'
                  className={`form-control ${errors.nama_kelurahan ? 'is-invalid' : ''}`}
                  value={formData.nama_kelurahan}
                  onChange={(e) => handleChange(e)} />
                {errors.nama_kelurahan && <p style={{ color: "red" }}>{errors.nama_kelurahan}</p>}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 mb-10">
              <label htmlFor='no_kk' className='form-label'>No KK :</label>
                <input type='text' name='no_kk'
                  className={`form-control ${errors.no_kk ? 'is-invalid' : ''}`}
                  value={formData.no_kk}
                  onChange={(e) => handleChange(e)} />
                {errors.no_kk && <p style={{ color: "red" }}>{errors.no_kk}</p>}
              </div>
              <div className="col-md-6 mb-10">
              <label htmlFor='kode_pos' className='form-label'>Kode Pos :</label>
                <input type='text' name='kode_pos'
                  className={`form-control ${errors.kode_pos ? 'is-invalid' : ''}`}
                  value={formData.kode_pos}
                  onChange={(e) => handleChange(e)} />
                {errors.kode_pos && <p style={{ color: "red" }}>{errors.kode_pos}</p>}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 mb-10">
              <label htmlFor='npwp' className='form-label'>NPWP :</label>
                <input type='text' name='npwp'
                  className={`form-control ${errors.npwp ? 'is-invalid' : ''}`}
                  value={formData.npwp}
                  onChange={(e) => handleChange(e)} />
                {errors.npwp && <p style={{ color: "red" }}>{errors.npwp}</p>}
              </div>
              <div className="col-md-6 mb-10">
              <label htmlFor='nama_wajib_pajak' className='form-label'>Nama Wajib Pajak :</label>
                <input type='text' name='nama_wajib_pajak'
                  className={`form-control ${errors.nama_wajib_pajak ? 'is-invalid' : ''}`}
                  value={formData.nama_wajib_pajak}
                  onChange={(e) => handleChange(e)} />
                {errors.nama_wajib_pajak && <p style={{ color: "red" }}>{errors.nama_wajib_pajak}</p>}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 mb-10">
              <label htmlFor='kewarganegaraan' className='form-label'>Kewarganegaraan :</label>
                <input type='text' name='kewarganegaraan'
                  className={`form-control ${errors.kewarganegaraan ? 'is-invalid' : ''}`}
                  value={formData.kewarganegaraan}
                  onChange={(e) => handleChange(e)} />
                {errors.kewarganegaraan && <p style={{ color: "red" }}>{errors.kewarganegaraan}</p>}
              </div>
              <div className="col-md-6 mb-10">
              <label htmlFor='status_perkawinan' className='form-label'>Status Perkawinan :</label>
                <input type='text' name='status_perkawinan'
                  className={`form-control ${errors.status_perkawinan ? 'is-invalid' : ''}`}
                  value={formData.status_perkawinan}
                  onChange={(e) => handleChange(e)} />
                {errors.status_perkawinan && <p style={{ color: "red" }}>{errors.status_perkawinan}</p>}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 mb-10">
              <label htmlFor='nama_istri_suami' className='form-label'>Nama Istri / Suami :</label>
                <input type='text' name='nama_istri_suami'
                  className={`form-control ${errors.nama_istri_suami ? 'is-invalid' : ''}`}
                  value={formData.nama_istri_suami}
                  onChange={(e) => handleChange(e)} />
                {errors.nama_istri_suami && <p style={{ color: "red" }}>{errors.nama_istri_suami}</p>}
              </div>
              <div className="col-md-6 mb-10">
              <label htmlFor='nip_istri_suami' className='form-label'>NIP Istri / Suami :</label>
                <input type='text' name='nip_istri_suami'
                  className={`form-control ${errors.nip_istri_suami ? 'is-invalid' : ''}`}
                  value={formData.nip_istri_suami}
                  onChange={(e) => handleChange(e)} />
                {errors.nip_istri_suami && <p style={{ color: "red" }}>{errors.nip_istri_suami}</p>}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 mb-10">
              <label htmlFor='pekerjaan_istri_suami' className='form-label'>Pekerjaan Istri / Suami :</label>
                <input type='text' name='pekerjaan_istri_suami'
                  className={`form-control ${errors.pekerjaan_istri_suami ? 'is-invalid' : ''}`}
                  value={formData.pekerjaan_istri_suami}
                  onChange={(e) => handleChange(e)} />
                {errors.pekerjaan_istri_suami && <p style={{ color: "red" }}>{errors.pekerjaan_istri_suami}</p>}
              </div>
              <div className="col-md-6 mb-10">
              <label htmlFor='no_telp_rumah' className='form-label'>No Telpon Rumah :</label>
                <input type='text' name='no_telp_rumah'
                  className={`form-control ${errors.no_telp_rumah ? 'is-invalid' : ''}`}
                  value={formData.no_telp_rumah}
                  onChange={(e) => handleChange(e)} />
                {errors.no_telp_rumah && <p style={{ color: "red" }}>{errors.no_telp_rumah}</p>}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 mb-10">
              <label htmlFor='bb' className='form-label'>Berat Badan :</label>
                <input type='text' name='bb'
                  className={`form-control ${errors.bb ? 'is-invalid' : ''}`}
                  value={formData.bb}
                  onChange={(e) => handleChange(e)} />
                {errors.bb && <p style={{ color: "red" }}>{errors.bb}</p>}
              </div>
              <div className="col-md-6 mb-10">
              <label htmlFor='tb' className='form-label'>Tinggi Badan :</label>
                <input type='text' name='tb'
                  className={`form-control ${errors.tb ? 'is-invalid' : ''}`}
                  value={formData.tb}
                  onChange={(e) => handleChange(e)} />
                {errors.tb && <p style={{ color: "red" }}>{errors.tb}</p>}
              </div>
              <div className="col-md-6 mb-10">
              <label htmlFor='gol_darah' className='form-label'>Gol Darah :</label>
                <input type='text' name='gol_darah'
                  className={`form-control ${errors.gol_darah ? 'is-invalid' : ''}`}
                  value={formData.gol_darah}
                  onChange={(e) => handleChange(e)} />
                {errors.gol_darah && <p style={{ color: "red" }}>{errors.gol_darah}</p>}
              </div>
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
          </form>
        </div>
      </LayoutDashboard>
    </div>
  )
}