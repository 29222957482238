import React, { useEffect, useState } from 'react'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'

import axios from 'axios'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import { useParams } from 'react-router'
import apiClient from '../../../../axiosConfig'

export const DataDiriRayonPage = () => {

    const { studentId } = useParams();
 
    const tabs = [
        { label: 'Siswa' },
        { label: 'Keluarga' },
        { label: 'Alamat' },
        { label: 'Dokumen' },
    ];
    const [activeTab, setActiveTab] = useState(0);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const [studentData, setStudentData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await apiClient.get(`${process.env.REACT_APP_API_ENDPOINT}/student/${studentId}`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            });
            setStudentData(response.data.student);
            // console.log(studentData, "data student")
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData()
    }, [studentId]);

    useEffect(() => {
        // console.log(studentData, "data student");
    }, [studentData]);


    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" tiga="Data Siswa" dua={studentData?.nama} urlDua="dashboard/pembimbing/siswa" urlSatu="/dashboard/petunjuk" />

                <div className='card-box pt-3 px-4 shadow-sm mb-20'>
                    <ul className="nav nav-pills nav-fill">
                        {tabs.map((tab, index) => (
                            <li className="nav-item cursor-pointer" key={index}>
                                <p className={`nav-link pointer ${activeTab === index
                                    ? 'active'
                                    : ''
                                    }`} aria-current="page" onClick={() => handleTabClick(index)}> {tab.label}</p>
                            </li>
                        ))}

                    </ul>
                </div>

                <div className='card-box p-4 shadow-sm'>

                    {studentData ? (
                        <>
                            <div className="tab-content">
                                {activeTab === 0 && <div>

                                    <div className="flex w-full overflow-x-auto">
                                        <table className="table table-striped">

                                            <tbody>
                                                <tr>
                                                    <td className='mengecil'>Nama</td>

                                                    <td>: <span className='ml-3' />{studentData?.nama}</td>
                                                </tr>
                                                <tr>
                                                    <td>NIS</td>

                                                    <td>:<span className='ml-3' />{studentData?.nis}</td>
                                                </tr>
                                                <tr>
                                                    <td>Jenis Kelamin</td>

                                                    <td>:<span className='ml-3' />{studentData?.jk}</td>
                                                </tr>
                                                <tr>
                                                    <td>Rombel</td>

                                                    <td>:  <span className='ml-3' />{studentData?.rombel}</td>
                                                </tr>
                                                <tr>
                                                    <td>Rayon</td>

                                                    <td>:  <span className='ml-3' />{studentData?.rayon}</td>
                                                </tr>
                                                <tr>
                                                    <td>NISN</td>

                                                    <td>:  <span className='ml-3' />{studentData?.nisn}</td>
                                                </tr>
                                                <tr>
                                                    <td>NIK</td>

                                                    <td>:  <span className='ml-3' />{studentData?.nik}</td>
                                                </tr>
                                                <tr>
                                                    <td>No. Kartu Keluarga</td>

                                                    <td>:  <span className='ml-3' />{studentData?.no_kk}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tempat Lahir</td>

                                                    <td>:  <span className='ml-3' />{studentData?.tempat_lahir}</td>
                                                </tr>
                                                <tr>
                                                    <td> Tanggal Lahir</td>

                                                    <td>:  <span className='ml-3' />{studentData?.tanggal_lahir}</td>
                                                </tr>
                                                <tr>
                                                    <td>No. Akta</td>

                                                    <td>:  <span className='ml-3' />{studentData?.no_akta}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>}
                                {activeTab === 1 && <div>
                                    <div className="flex w-full overflow-x-auto">
                                        <table className="table table-striped">

                                            <tbody>
                                                <tr>
                                                    <td className='mengecil'>Nama Ayah</td>
                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.nama_ayah}</td>
                                                </tr>
                                                <tr>
                                                    <td>NIK Ayah</td>
                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.nik_ayah}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tanggal Lahir Ayah</td>

                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.tanggal_lahir_ayah}</td>
                                                </tr>
                                                <tr>
                                                    <td>Pendidikan Ayah</td>

                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.pendidikan_ayah}</td>
                                                </tr>
                                                <tr>
                                                    <td>Pekerjaan Ayah</td>

                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.pekerjaan_ayah}</td>
                                                </tr>
                                                <tr>
                                                    <td>Penghasilan Ayah</td>

                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.penghasilan_ayah}</td>
                                                </tr>
                                                <tr>
                                                    <td>Nama Ibu</td>

                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.nama_ibu}</td>
                                                </tr>
                                                <tr>
                                                    <td>NIK Ibu</td>

                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.nik_ibu}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tanggal Lahir Ibu</td>

                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.tanggal_lahir_ibu}</td>
                                                </tr>
                                                <tr>
                                                    <td>Pendidikan Ibu</td>

                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.pendidikan_ibu}</td>
                                                </tr>
                                                <tr>
                                                    <td>Pekerjaan Ibu</td>

                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.pekerjaan_ibu}</td>
                                                </tr>
                                                <tr>
                                                    <td>Penghasilan Ibu</td>

                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.penghasilan_ibu}</td>
                                                </tr>
                                                <tr>
                                                    <td>Nama Wali</td>

                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.nama_wali}</td>
                                                </tr>
                                                <tr>
                                                    <td>NIK Wali</td>

                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.nik_wali}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tanggal Lahir Wali</td>

                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.tanggal_lahir_wali}</td>
                                                </tr>
                                                <tr>
                                                    <td>Pendidikan Wali</td>

                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.pendidikan_wali}</td>
                                                </tr>
                                                <tr>
                                                    <td>Pekerjaan Wali</td>

                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.pekerjaan_wali}</td>
                                                </tr>
                                                <tr>
                                                    <td>Penghasilan Wali</td>

                                                    <td>: <span className='ml-3' />{studentData?.keluarga_id?.penghasilan_wali}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>}
                                {activeTab === 2 && <div>
                                    <div className="flex w-full overflow-x-auto">
                                        <table className="table table-striped">
                                           
                                            <tbody>
                                                <tr>
                                                    <td className="mengecil">Alamat Jalan</td>

                                                    <td>: <span className='ml-3' /> {studentData?.alamat}</td>
                                                </tr>
                                                <tr>
                                                    <td>RT</td>

                                                    <td>: <span className='ml-3' /> {studentData?.rt}</td>
                                                </tr>
                                                <tr>
                                                    <td>RW</td>

                                                    <td>: <span className='ml-3' /> {studentData?.rw}</td>
                                                </tr>
                                                <tr>
                                                    <td>Nama Dusun</td>

                                                    <td>: <span className='ml-3' /> {studentData?.nama_dusun}</td>
                                                </tr>
                                                <tr>
                                                    <td>Kecamatan</td>

                                                    <td>: <span className='ml-3' /> {studentData?.kecamatan}</td>
                                                </tr>
                                                <tr>
                                                    <td>Nama Kota</td>

                                                    <td>: <span className='ml-3' /> {studentData?.nama_kota}</td>
                                                </tr>
                                                <tr>
                                                    <td>Kode Pos</td>

                                                    <td>: <span className='ml-3' /> {studentData?.kode_pos}</td>
                                                </tr>
                                                <tr>
                                                    <td>Provinsi</td>

                                                    <td>: <span className='ml-3' /> {studentData?.provinsi}</td>
                                                </tr>
                                                <tr>
                                                    <td>Transportasi</td>

                                                    <td>: <span className='ml-3' /> {studentData?.transportasi}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>}
                                {activeTab === 3 && <div>
                                    <h4 className='mb-10'>Data Dokumen {studentData.nama}</h4>
                                </div>}

                            </div>
                        </>
                    ) : (<>Loading..</>)}
                    <p></p>




                </div>




            </LayoutDashboard>
        </div>
    )
}

export default DataDiriRayonPage