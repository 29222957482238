import React, { createContext, useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import apiClient from '../axiosConfig';

export const RombelContext = createContext();


export const RombelProvider = ({ children }) => {
  const [rombels, setRombel] = useState([])
    const getRombels = async () => {
        try {
            const response = await apiClient.get(`${process.env.REACT_APP_API_ENDPOINT}/rombel`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            });
            setRombel(response?.data.rombel);
        } catch (error) {
            console.log("Terjadi kesalahan saat mengambil data Rombel");
        }
    };

    useEffect(() => {
        getRombels();
    }, [])

    const state = {
       rombels,
       getRombels
    }
    return (
        <RombelContext.Provider value={state}>
            {children}
        </RombelContext.Provider>
    );
};

export default RombelProvider;


