import React, { useState, useEffect, useContext } from 'react';
import { BsEyeFill, BsPrinterFill, BsTrashFill } from 'react-icons/bs';
import { BiPlus } from 'react-icons/bi';
import LayoutDashboard from '../../../layouts/LayoutDashboard';
import GelBreadcrumb from '../../../components/globalcomp/GelBreadcrumb';
import { confirmDelete } from '../../../function/delete';
import { AllStudentContext } from '../../../context/AllStudentContext';
import { Kosong } from '../../../components/globalcomp/Kosong';
import { Link, useNavigate } from 'react-router-dom';
import handleExportClick from '../../../function/export';
import importService from '../../../function/import';
import axios from 'axios';
import Swal from 'sweetalert2';
import apiClient from '../../../axiosConfig';
// import handleValidateStudent from '../../../function/verifikasi';
// import cancelVerifikasiStudent from '../../../function/cancelVerifikasi';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const StudentPage = () => {
    const navigate = useNavigate();
    const { allStudent, getAllStudent } = useContext(AllStudentContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    // const [allSiswas, setAllSiswas] = useState({});
    const itemsPerPage = 100;

    const apiExport = '/export-data-excell-student';
    const fileName = 'student-wikrama.xlsx';

    useEffect(() => {
        document.title = 'Data Siswa | Sidata';
        // setAllSiswas(allStudent);
    }, [getAllStudent]);

    const filtersStudents = allStudent.filter((siswa) => {
        if (siswa.nis && siswa.nama) {
          return siswa.nis.toLowerCase().includes(searchTerm.toLowerCase()) ||
                 siswa.nama.toLowerCase().includes(searchTerm.toLowerCase());
        } else {
          return false;
        }
      });

    // console.log(allStudent)

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    let currentItems = '';

    if (searchTerm === '') {
        currentItems = filtersStudents.slice(indexOfFirstItem, indexOfLastItem);
    } else {
        currentItems = filtersStudents;
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [file, setFile] = useState([]);

    const handleOnChange = (e) => {
        setFile(e.target.files);
        console.log(e.target.files, "data file")
    };

    const handleOnStudent = async (event) => {
        event.preventDefault();
        const apiImportStudent = apiEndpoint + "/import/excel/student";
        if (!file) {
          console.error("File tidak ditemukan!");
          return;
        }
        await importService(file, "file_student", apiImportStudent, getAllStudent);
      };
      

    const handlePasswordChange = async (e, user_id, password) => {
        e.preventDefault();

        const result = await Swal.fire({
            title: "Konfirmasi",
            text: "Apakah Anda yakin mengganti password?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText: "Tidak",
        });

        if (result.isConfirmed) {
            try {
                await apiClient.put(`${apiEndpoint}/change-password/${user_id}`, {'password': password});
                Swal.fire({
                    title: "Sukses!",
                    text: `Password baru: ${password}`,
                    icon: "success",
                }).then(() => {
                    window.location.reload();
                });
            } catch (error) {
                console.log(error, "error ini");
            }
        }
    };

    const handleUpdateRombelAjaran = async () => {
        try{
            await apiClient.post(`${apiEndpoint}/update-rombel-siswa`)
            Swal.fire({
                title: "Sukses!",
                text: `Berhasil di Update`,
                icon: "success",
            }).then(() => {
                window.location.reload();
            });
        } catch (error) {
            console.log(error, "error ini");
        }
    }

    // const printPdf = async (user_id) => {
    //     try {
    //         console.log(user_id);
    //         const res = await axios.get(`${apiEndpoint}/print-pdf/${user_id}`);
    //         console.log(res)
    //     } catch (error) {
    //         console.log(error, "errorr");
    //     }
    // }

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Home" dua="Data Semua Siswa" urlSatu="/admin/dashboard/student" />
                <div className='card-box shadow-sm p-4 mb-5'>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                            <Link to="/dashboard/student/create">
                                <button className="btn bg-blue text-white"><BiPlus /> Buat Akun Siswa</button>
                            </Link>
                            <div>
                                <button className='btn btn-success ml-3' onClick={() => handleExportClick(apiEndpoint + apiExport, fileName)}>
                                    Export Excel
                                </button>
                            </div>
                            <div>
                                <button type="button" class="btn btn-primary ml-3" data-toggle="modal" data-target="#exampleModal">
                                    Import Excel
                                </button>

                                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Masukan File</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <form className='form-group mx-3 my-3' onSubmit={handleOnStudent}>
                                                <input type="file" id="file" className='form-control mb-3' onChange={handleOnChange} />
                                                <button type='submit' className='btn bg-blue text-white'>Import Data</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {/* <button className='btn btn-warning ml-3' onClick={() => handleUpdateRombelAjaran()}>
                                    Update Rombel
                                </button> */}
                            </div>

                        </div>
                        <div>
                            <input
                                type="search"
                                className='form-control'
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Cari nama atau NIS..."
                            />
                        </div>

                    </div>
                    <div class="table-responsive">
                        <table className="table mt-3 table-borderless">
                            <thead className='thead-dark  custom-header'>
                                <tr>
                                    <th>#</th>
                                    <th>Nama Lengkap</th>
                                    {/* <th>Email</th> */}
                                    <th>NIS</th>
                                    <th>Rombel</th>
                                    <th>Rayon</th>
                                    <th>Data Diri</th>
                                    <th>Data Keluarga</th>
                                    <th>Data Dokumen</th>
                                    <th>Detail</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>


                            <tbody>
                                {allStudent && allStudent.length > 0 ? (
                                    currentItems.length > 0 ? (
                                        currentItems.map((item, index) => (
                                            <tr key={item.id}>
                                                <td>{searchTerm === '' ? (currentPage - 1) * itemsPerPage + index + 1 : index+1}</td>
                                                <td>{item?.nama}</td>
                                                {/* <td>{item?.email}</td> */}
                                                <td>{item?.nis}</td>
                                                <td>{item?.rombel}</td>
                                                <td>{item?.rayon}</td>
                                                <td>
                                                    {
                                                        item.status_data_diri === "Pending" || item.status_data_diri === "Konfirmasi" ? (
                                                        (item.status_data_diri === "Pending") ? (
                                                            <button className='btn btn-warning ml-3'>
                                                            Belum Isi
                                                            </button>
                                                        ) : (
                                                            <button className='btn btn-success ml-3'>
                                                            Sudah Isi
                                                            </button>
                                                        )
                                                        ) : (

                                                            <div class="dropdown">
                                                            <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            Divalidasi PS
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                                <li><a class="dropdown-item" href="#">Cancel</a></li>
                                                            </ul>
                                                            </div>
                                                        )
                                                    }

                                                    </td>
                                                    <td>

                                                    {
                                                        item.status_data_family === "Pending" || item.status_data_family === "Konfirmasi" ? (
                                                        (item.status_data_family === "Pending") ? (
                                                            <button className='btn btn-warning ml-3'>
                                                            Belum Isi
                                                            </button>
                                                        ) : (
                                                            <button className='btn btn-success ml-3'>
                                                            Sudah Isi
                                                            </button>
                                                        )
                                                        ) : (

                                                            <div class="dropdown">
                                                            <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            Divalidasi PS
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                                <li><a class="dropdown-item" href="#">Cancel</a></li>
                                                            </ul>
                                                            </div>
                                                        )
                                                    }

                                                    </td>
                                                    <td>

                                                    {
                                                        item.status_data_dokumen === "Pending" || item.status_data_dokumen === "Konfirmasi" ? (
                                                        (item.status_data_dokumen === "Pending") ? (
                                                            <button className='btn btn-warning ml-3'>
                                                            Belum Isi
                                                            </button>
                                                        ) : (
                                                            <button className='btn btn-success ml-3'>
                                                            Sudah Isi
                                                            </button>
                                                        )
                                                        ) : (

                                                            <div class="dropdown">
                                                            <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            Divalidasi PS
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                                <li><a class="dropdown-item" href="#">Cancel</a></li>
                                                            </ul>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                                <td>
                                                    <Link to={`/dashboard/student/detail/${item._id}`}>
                                                        <button className='btn btn-sm btn-success rounded-md mx-1'><BsEyeFill /> Detail</button>
                                                    </Link>
                                                    <Link target="blank" to={`/print-pdf/${item.user_id}`}>
                                                        <button className='btn btn-sm btn-info rounded-md mx-1'><BsPrinterFill /> Print</button>
                                                    </Link>

                                                    {/* <Link target="blank" to={`/dashboard/student/pdf-print/${item.user_id}`}> */}
                                                        {/* <a href={apiEndpoint + '/print-pdf/' + item.user_id} target='_blank' className='btn btn-sm btn-info rounded-md mx-1'><BsPrinterFill /> Print</a> */}
                                                    {/* </Link> */}
                                                </td>
                                                <td>
                                                    <div className='gap-2 flex flex-row min-w-[200px]'>
                                                        <button className='btn btn-warning btn-sm' onClick={(e) => handlePasswordChange(e,item.user_id,item.nis)}>
                                                            <BsTrashFill /> Reset Password
                                                        </button>
                                                        <button className='btn btn-danger btn-sm' onClick={() => confirmDelete(item._id, 'student/delete', navigate, getAllStudent)}>
                                                            <BsTrashFill /> Hapus
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6" className="text-center">Data tidak ditemukan</td>
                                        </tr>
                                    )
                                ) : (
                                    <Kosong />
                                )}
                            </tbody>

                        </table>
                    </div>
                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className="page-item">
                                <button className="page-link" onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                            </li>
                            {Array.from({ length: Math.ceil(filtersStudents.length / itemsPerPage) }).map((_, index) => {
                                if (Math.abs(currentPage - (index + 1)) <= 4 || index === 0 || index === Math.ceil(filtersStudents.length / itemsPerPage) - 1) {
                                    return (
                                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => paginate(index + 1)}>{index + 1}</button>
                                        </li>
                                    );
                                } else if (Math.abs(currentPage - (index + 1)) === 5 && Math.ceil(filtersStudents.length / itemsPerPage) > 10) {
                                    return <li key={index} className="page-item disabled"><span className="page-link">...</span></li>;
                                }
                            })}
                            <li className="page-item">
                                <button className="page-link" onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(filtersStudents.length / itemsPerPage)}>Next</button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </LayoutDashboard>
        </div>
    );
}


