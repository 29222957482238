import React, { useContext, useEffect, useState } from 'react';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import GtkContext from '../../../../context/GtkContext';
import { Link } from 'react-router-dom';
import { Kosong } from '../../../../components/globalcomp/Kosong';
import { BsPencil } from 'react-icons/bs';
import { confirmDelete } from '../../../../function/delete';
import { BsTrash } from 'react-icons/bs';

export const DataPribadiGtk = () => {
    document.title = "Data Diri";

    const { gtk, getGtk } = useContext(GtkContext);
    const [userGtk, setUserGtk] = useState(null);

    useEffect(() => {
        getGtk();
    }, []);

    useEffect(() => {
        if (gtk.gtk) {
            setUserGtk(gtk.gtk);
        }
    }, [gtk.gtk]);

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" dua="Data Pribadi" tiga="Data Diri" urlSatu="/dashboard/pembimbing" />


                <table className="table table-borderless">
                    <thead className='thead-dark'>
                        <tr>
                            <th scope="col">Alamat Jalan</th>
                            <th scope="col">RT</th>
                            <th scope="col">RW</th>
                            <th scope="col">Nama Kelurahan</th>
                            <th scope="col">Kecamatan</th>
                            <th scope="col">Nomor Kartu Keluarga</th>
                            <th scope="col">Kode Pos</th>
                            <th scope="col">Agama & Kepercayaan</th>
                            <th scope="col">NPWP</th>
                            <th scope="col">Nama Wajib Pajak</th>
                            <th scope="col">Kewarganegaraan</th>
                            <th scope="col">Status Perkawinan</th>
                            <th scope="col">Nama Istri / Suami</th>
                            <th scope="col">NIP Suami</th>
                            <th scope="col">Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userGtk ? (
                            <tr>
                                <td>{userGtk.alamat}</td>
                                <td>{userGtk.rt}</td>
                                <td>{userGtk.rw}</td>
                                <td>{userGtk.nama_kelurahan}</td>
                                <td>{userGtk.kecamatan}</td>
                                <td>{userGtk.no_kk}</td>
                                <td>{userGtk.kode_pos}</td>
                                <td>{userGtk.agama}</td>
                                <td>{userGtk.npwp}</td>
                                <td>{userGtk.nama_wajib_pajak}</td>
                                <td>{userGtk.kewarganegaraan}</td>
                                <td>{userGtk.status_perkawinan}</td>
                                <td>{userGtk.nama_istri_suami}</td>
                                <td>{userGtk.nip_istri_suami}</td>
                                <td className='flex flex-row gap-1'>
                                            <Link to={`/dashboard/pembimbing/data-diri/lengkapi/${userGtk._id}`}>
                                                <button className='btn btn-warning btn-sm text-white'>
                                                    <BsPencil />
                                                </button>
                                            </Link>
                                            <button onClick={() => confirmDelete(userGtk._id)} className='btn ml-2 btn-sm btn-danger'>
                                                <BsTrash />
                                            </button>
                                        </td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan="8">
                                    <Kosong />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </LayoutDashboard>
        </div>
    );
};